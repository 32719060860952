import { sleep } from '@account/typetanium';
import { useEffect } from 'react';
export const VOICEOVER_FOCUS_WAITTIME = 350;
export function useAccessibilityFocus(uid) {
    return useEffect(() => {
        const timeout = async () => {
            var _a;
            await sleep(VOICEOVER_FOCUS_WAITTIME);
            (_a = document.getElementById(uid)) === null || _a === void 0 ? void 0 : _a.focus();
        };
        timeout();
    }, [uid]);
}
