import { modal } from '@account/amk-styles';
import { useUID } from '@account/react-hooks';
import React, { useLayoutEffect, useRef } from 'react';
import { disableElement, restoreElement } from './axUtils';
const [, element] = modal;
export const ModalContent = ({ disabled = false, id: _id, children }) => {
    const id = useUID({ defaultValue: _id, prefix: element('content') + '-' });
    const axElementsRef = useRef(new Map());
    const contentRef = useRef(null);
    useLayoutEffect(() => {
        if (disabled) {
            disableElement(contentRef.current, axElementsRef.current, id);
        }
        return () => {
            restoreElement(contentRef.current, axElementsRef.current, id);
        };
    }, [disabled]);
    return (React.createElement("div", { id: id, ref: contentRef, className: element('content', { disabled }) }, children));
};
