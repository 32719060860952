import { pickBy } from '../pickBy';
/**
 * Creates an object composed of the `object` properties with truthy values.
 * @category Object
 * @returns The new object.
 * @example
 * pickByTruthy({ a: true, b: false, c: 0, d: 42, e: null, f: undefined })
 * // => { a: true, d: 42 }
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function pickByTruthy(object) {
    return pickBy(object, Boolean);
}
