import { text } from '@account/amk-styles';
import React, { createElement } from 'react';
const [bemBlock] = text;
export const Text = ({ block = false, ltr = false, heading, typography, color, weight, spacing, lineClamp, children }) => {
    const className = bemBlock({
        [`typography-${typography}`]: typography,
        [`color-${color}`]: color,
        [`weight-${weight}`]: weight,
        [`line-clamp-${lineClamp}`]: lineClamp,
        [spacing === true ? 'spacing' : `spacing-${spacing}`]: spacing
    });
    const elementProps = {
        className,
        children: ltr ? React.createElement("bdo", { dir: "ltr" }, children) : children
    };
    const element = heading ? `h${heading}` : block ? 'div' : 'span';
    return createElement(element, elementProps);
};
