import { modal } from '@account/amk-styles';
import { useGlobalEvent, useOutsideEvent, useUID } from '@account/react-hooks';
import { FontWeight, Icon, IconGlyph } from '@account/standards-components';
import React, { useCallback, useRef } from 'react';
import { createPortal } from 'react-dom';
import { KeyboardEventKey } from '../../utils';
import { Button } from '../Button';
import { Spinner } from '../Spinner';
import { useModalAccessibilityEffects } from './hooks/useModalAccessibilityEffects';
const [block, element] = modal;
export const Modal = ({ axAutoFocus = true, axCloseButtonLabel, blurryOverlay = true, children, focusElementId, id: _id, inactive = false, onDismiss, shouldDismissOnBackdropClick = false, shouldDismissOnEscapeKeyPress = true, visible, alert, container = document.body, axWorkingLabel, axLabel, workingText, tabIndex }) => {
    const id = useUID({ defaultValue: _id, prefix: block() + '-' });
    const modalRef = useRef(null);
    useModalAccessibilityEffects({
        autoFocus: axAutoFocus,
        focusElementId,
        modalId: id,
        visible
    });
    const onEscape = useCallback(function onModalEscape(event) {
        if (visible &&
            event.key === KeyboardEventKey.Escape &&
            modalRef.current.getAttribute('aria-hidden') !== 'true') {
            event.stopPropagation();
            onDismiss();
        }
    }, [onDismiss, visible]);
    if (shouldDismissOnEscapeKeyPress) {
        useGlobalEvent('keydown', onEscape);
    }
    const [modalDialogRefNode, setModalDialogRef] = React.useState(null);
    const onClickOutsideListener = React.useCallback((event) => {
        event.stopPropagation();
        if (shouldDismissOnBackdropClick) {
            onDismiss();
        }
    }, [onDismiss, shouldDismissOnBackdropClick]);
    useOutsideEvent(modalDialogRefNode, 'click', onClickOutsideListener);
    const closeButton = onDismiss && axCloseButtonLabel && (React.createElement("div", { className: element('close') },
        React.createElement(Button, { "aria-label": axCloseButtonLabel, bare: true, onClick: onDismiss },
            React.createElement(Icon, { glyph: IconGlyph.Close, weight: FontWeight.SemiBold }))));
    workingText = workingText || axWorkingLabel;
    return visible
        ? createPortal(React.createElement("aside", { id: id, ref: modalRef, className: block({
                'blurry-overlay': blurryOverlay,
                inactive,
                alert
            }) },
            React.createElement("div", { ref: setModalDialogRef, className: element('dialog'), role: "dialog", "aria-label": axLabel, onClick: stopPropagation, tabIndex: tabIndex },
                workingText ? (React.createElement("div", { className: element('working') },
                    React.createElement(Spinner, { axLabel: workingText }),
                    React.createElement("span", null, workingText))) : (children),
                closeButton)), container)
        : null;
};
function stopPropagation(event) {
    event.stopPropagation();
}
