import { wrap } from '../wrap';
/**
 * Creates a function that negates the result of the predicate function.
 * The predicate is invoked with the `this` binding and arguments of
 * the created function.
 * @category Function
 * @returns The created function.
 * @example
 * negate(Boolean)(true)
 * // => false
 *
 * negate(Boolean)(false)
 * // => true
 *
 * negate(Boolean)('')
 * // => true
 *
 * negate(isEven)(3)
 * // => true
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function negate(predicate) {
    return wrap(predicate, negate.name, function (...args) {
        return !predicate.apply(this, args);
    });
}
