import React from 'react';
import { Card } from '.';
import { Button } from '../Button';
export const ModuleCard = ({ onClick, noWrap = true, ...restProps }) => {
    const cardProps = {
        theme: onClick ? 'module' : 'module-disabled',
        heading: '3',
        ...restProps
    };
    return onClick ? (React.createElement(Button, { bare: true, expand: true, onClick: onClick },
        React.createElement(Card, { noWrap: noWrap, ...cardProps }))) : (React.createElement(Card, { shadow: false, border: false, noWrap: noWrap, ...cardProps }));
};
