import { onIdle } from '@account/idle';
import { uuid } from '@account/uid';
import { WindowConnection } from '@account/winio';
import { onElementResize } from './onElementResize';
const STATUS_INTERVAL_TIME = 60000;
export class AMKClient {
    constructor(targetOrigin, options) {
        this.isIdle = false;
        this.connection = new WindowConnection(window.parent, targetOrigin, {
            namespace: 'amk:module'
        });
        this.connecting = this.connection.connect();
        this.connecting.then(() => {
            if (options === null || options === void 0 ? void 0 : options.autoResize) {
                const { width, height } = options.autoResize.getBoundingClientRect();
                this.connection.emit('resize', {
                    width,
                    height
                });
                onElementResize(options.autoResize, (newSize) => {
                    this.connection.emit('resize', newSize);
                });
            }
            setInterval(() => {
                this.connection.emit('status', { idle: this.isIdle });
            }, STATUS_INTERVAL_TIME);
        });
        onIdle((_isIdle) => {
            this.isIdle = _isIdle;
        });
    }
    async emit(event, payload) {
        await this.connecting;
        this.connection.emit(event, payload);
    }
    show() {
        this.emit('show');
    }
    hide(data) {
        this.emit('hide', data);
    }
    openModule(name, options) {
        return new Promise((resolve, reject) => {
            const openModuleId = uuid();
            const removeClosedListener = this.connection.on('moduleClosed', ({ uuid, data }) => {
                if (uuid === openModuleId) {
                    removeListeners();
                    resolve(data);
                }
            });
            const removeFailedListener = this.connection.on('openModuleFailed', ({ uuid }) => {
                if (uuid === openModuleId) {
                    removeListeners();
                    reject();
                }
            });
            function removeListeners() {
                removeClosedListener();
                removeFailedListener();
            }
            this.emit('openModule', {
                name,
                uuid: openModuleId,
                data: options
            });
        });
    }
    renewAuthTokens() {
        return new Promise((resolve) => {
            this.emit('renewAuthTokens');
            const removeListener = this.connection.on('authTokensRenewed', () => {
                removeListener();
                resolve();
            });
        });
    }
}
