import classnames from 'classnames';
import React from 'react';
import './Grid.scss';
const viewportClasses = (viewportName, config) => {
    if (!config)
        return {};
    if (typeof config !== 'object') {
        return { [`${viewportName}-${config}`]: true };
    }
    return {
        [`${viewportName}-${config.size}`]: config.size,
        [`${viewportName}-centered`]: config.centered,
        [`${viewportName}-uncentered`]: config.centered === false,
        [`${viewportName}-offset-${config.offset}`]: config.offset,
        [`${viewportName}-order-${config.order}`]: config.order,
        [`${viewportName}-last`]: config.last,
        [`${viewportName}-notlast`]: config.last === false
    };
};
export const GridColumn = ({ align, large, medium, small, ...divProps }) => {
    return (React.createElement("div", { className: classnames('column', {
            [`align-self-${align}`]: align,
            ...viewportClasses('large', large),
            ...viewportClasses('medium', medium),
            ...viewportClasses('small', small)
        }), ...divProps }));
};
