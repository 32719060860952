import { useUID } from '@account/react-hooks';
import { pickByTruthy } from '@account/typetanium';
import classnames from 'classnames';
import React, { forwardRef } from 'react';
import { bem, UtilityClass } from '../../utils';
import { getIsError } from '../../utils/getIsError';
import { AxLabeled } from '../AxLabeled';
import { FormIcon } from '../FormIcon';
import { FormIconButton } from '../FormIconButton';
import { FormMessage } from '../FormMessage';
import { Icon, IconGlyph } from '../Icon';
import { TextFieldCounter } from '../TextFieldCounter';
import './TextField.scss';
const [block, element] = bem('form-textbox');
const _TextField = ({ 'aria-describedby': ariaDescribedBy, children, counter, errorMessage: _errorMessage, id: _id, isError: _isError, isValid = false, labelText, leftIconProps, prefix, rightIconProps, type = 'text', value, ltr = false, ...inputProps }, ref) => {
    const id = useUID({ defaultValue: _id, prefix: block() + '-' });
    const remaining = counter ? counter.limit - String(value).length : 0;
    const errorMessage = counter && remaining < 0 ? counter.errorMessage : _errorMessage;
    const isError = getIsError({ isError: _isError, errorMessage });
    const errorId = (errorMessage === null || errorMessage === void 0 ? void 0 : errorMessage.children) && isError ? `${id}_error` : '';
    const labelId = `${id}_label`;
    return (React.createElement("div", { className: classnames(block({
            'with-button': Boolean(children),
            'with-counter': Boolean(counter),
            'with-countrycode': prefix ? prefix.children.length > 2 : false,
            'with-countrycode-small': prefix
                ? prefix.children.length <= 2
                : false,
            'with-lefticon': Boolean(leftIconProps),
            'with-righticon': Boolean(rightIconProps) || isValid
        }), { [UtilityClass.IsError]: isError }) },
        ' ' /* fix Safari VO editing bug in Mojave <rdar://problem/62887454> */,
        React.createElement("input", { ...pickByTruthy({
                'aria-describedby': classnames(errorId, ariaDescribedBy),
                'aria-invalid': isError
            }), "aria-labelledby": labelId, autoCorrect: "off", autoCapitalize: "off", className: classnames(element('input', { ltr }), value && 'form-textbox-entered'), id: id, ref: ref, spellCheck: false, type: type, value: value, ...inputProps }),
        React.createElement("label", { id: labelId, "aria-hidden": true, className: element('label') }, labelText),
        children,
        isError && (errorMessage === null || errorMessage === void 0 ? void 0 : errorMessage.children) && (React.createElement(FormMessage, { id: errorId, ...errorMessage })),
        counter && (React.createElement(TextFieldCounter, { isError: isError, axLabel: counter.axLabel }, counter.format(remaining))),
        prefix && (React.createElement("span", { className: element('countrycode') },
            React.createElement(AxLabeled, { ...prefix }))),
        leftIconProps && (React.createElement("span", { className: element('lefticon') },
            React.createElement(FormIcon, { ...leftIconProps }))),
        (isValid || rightIconProps) && (React.createElement("div", { className: element('righticon') }, isValid ? (React.createElement(Icon, { glyph: IconGlyph.Check })) : rightIconProps.onClick ? (React.createElement(FormIconButton, { ...rightIconProps })) : (React.createElement(FormIcon, { ...rightIconProps }))))));
};
export const TextField = forwardRef(_TextField);
