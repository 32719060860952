/**
 * Checks if `value` is `null` or `undefined`.
 * @param value The value to check.
 * @returns `true` if `null` or `undefined`; else, `false`.
 * @example
 * isNullish(null)
 * // => true
 *
 * isNullish(void 0)
 * // => true
 *
 * isNullish(NaN)
 * // => false
 */
export function isNullish(value) {
    return value == null;
}
