import React, { ReactElement, useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import FamilyMembers from '@apps/family-members'
import MemberDetails from '@apps/member-details'
import Api, {
  FamilyDetails,
  UserPhotoData,
  getAppConfig,
  AppConfigResponse,
} from '@lib/api'
import Amk from '@lib/amk'
import ApiContext from '@components/api-context'
import I18nContext from '@components/i18n-context'
import I18n from '@lib/i18n'
import { AMKClient } from '@account/amk-client'
import ConfirmUnlink from '@apps/confirm-unlink'
import {getQueryParamValue} from '@lib/util'

const rootElem = document.getElementById('app')
const UT_ERROR_LOG_SCRIPT =
  'https://appleid-ut.corp.apple.com/appleauth/jsapi/winerror/winerror.0.1.7.js'
const PROD_ERROR_LOG_SCRIPT =
  'https://appleid.cdn-apple.com/appleauth/static/jsapi/winerror/winerror.0.1.7.js'

const App = (): ReactElement | null => {
  const [appConfig, setAppConfig] = useState<AppConfigResponse>()
  const [isScriptAttached, setIsScriptAttached] = useState(false)
  const [familyDetails, setFamilyDetails] = useState<FamilyDetails>()
  const [memberPhotos, setMemberPhotos] = useState<UserPhotoData[]>([])
  const [i18n, setI18n] = useState<I18n>()
  const [amkClient, setAmkClient] = useState<AMKClient>()
  const [isInitialized, setIsInitialized] = useState(false)
  const [isDataFetched, setIsDataFetched] = useState(false)
  const [api, setApi] = useState<Api>()

  const initializeAmkClientAndApi = async () => {
    const envParam = getQueryParamValue('env')
    //@ts-ignore
    const appConfigResponse = await getAppConfig(envParam)
    if (appConfigResponse?.idmsUrl) {
      setAppConfig(appConfigResponse)
      const amkClient = new Amk(rootElem).initializeClient(
        appConfigResponse.idmsUrl
      )
      setAmkClient(amkClient)
      setApi(
        new Api({
          renewAuthToken: async () => {
            return await amkClient.renewAuthTokens()
          },
        })
      )
    }
  }

  const initializeApp = async (api: Api) => {
    const [familyDetails, i18nApiResponse] = await Promise.all([
      api.getFamilyDetails(),
      api.getI18nInformation(),
    ])
    setFamilyDetails(familyDetails)
    if (i18nApiResponse) {
      setI18n(new I18n(i18nApiResponse))
    }
  }

  const initializePhotos = async (api: Api) => {
    const memberPhotos = await api.getMemberPhotos()
    setMemberPhotos(memberPhotos)
  }

  // On mount.
  useEffect(() => {
    initializeAmkClientAndApi()
  }, [])

  // Attach logging script on startup
  useEffect(() => {
    if (appConfig?.idmsUrl && !isScriptAttached) {
      const script = document.createElement('script')
      script.async = true
      script.src = appConfig.idmsUrl.includes('appleid.apple.com')
        ? PROD_ERROR_LOG_SCRIPT
        : UT_ERROR_LOG_SCRIPT
      document.head.appendChild(script)
      setIsScriptAttached(true)
      // Cleanup function
      return () => {
        document.head.removeChild(script)
      }
    }
  }, [appConfig, isScriptAttached])

  useEffect(() => {
    if (api && !isDataFetched) {
      initializeApp(api)
      // Don't block client start on fetching photos. We can show silhouettes in the meantime.
      initializePhotos(api)
      setIsDataFetched(true)
    }
  }, [api, isDataFetched])

  useEffect(() => {
    if (familyDetails && amkClient && i18n && !isInitialized) {
      // Set document direction on HTML tag.
      document.documentElement.dir = i18n.isRtl ? 'rtl' : 'ltr'
      amkClient.show()
      setIsInitialized(true)
    }
  }, [familyDetails, amkClient, i18n, isInitialized])

  if (!amkClient) {
    return null
  }

  return (
    <ApiContext.Provider value={api}>
      <I18nContext.Provider value={i18n}>
        <Router>
          <Switch>
            <Route path="/members">
              <FamilyMembers
                amkClient={amkClient}
                familyDetails={familyDetails}
                memberPhotos={memberPhotos}
              />
            </Route>
            <Route path="/member-details">
              <MemberDetails
                amkClient={amkClient}
                familyDetails={familyDetails}
                memberPhotos={memberPhotos}
              />
            </Route>
            <Route path="/confirm-unlink">
              <ConfirmUnlink
                amkClient={amkClient}
                familyDetails={familyDetails}
              />
            </Route>
            <Route path="/">
              <FamilyMembers
                amkClient={amkClient}
                familyDetails={familyDetails}
                memberPhotos={memberPhotos}
              />
            </Route>
          </Switch>
        </Router>
      </I18nContext.Provider>
    </ApiContext.Provider>
  )
}

ReactDOM.render(<App />, rootElem)
