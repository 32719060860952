import classnames from 'classnames';
import React from 'react';
import { bem, UtilityClass } from '../../utils';
import { AxLabeled } from '../AxLabeled';
const [block, element] = bem('form-textbox-counter');
export const TextFieldCounter = ({ axLabel, children, isError = false, ...wrapperProps }) => {
    return (React.createElement("div", { className: classnames(element('wrapper'), {
            [UtilityClass.IsError]: isError
        }), ...wrapperProps },
        React.createElement(AxLabeled, { axLabel: axLabel },
            React.createElement("span", { className: block() }, children))));
};
