function isSizeEqual(a, b) {
    return a.width === b.width && a.height === b.height;
}
function getContentSizeFromResizeObserverEntry(entry) {
    let size;
    if (entry.contentBoxSize) {
        // Checking for chrome using a non-standard array
        if (entry.contentBoxSize[0]) {
            size = {
                width: entry.contentBoxSize[0].inlineSize,
                height: entry.contentBoxSize[0].blockSize
            };
        }
        else {
            const contentBoxSize = entry.contentBoxSize;
            size = {
                width: contentBoxSize.inlineSize,
                height: contentBoxSize.blockSize
            };
        }
    }
    else {
        size = {
            width: entry.contentRect.width,
            height: entry.contentRect.height
        };
    }
    return size;
}
export function onElementResize(element, callback) {
    let size;
    const resizeObserver = new ResizeObserver((entries) => {
        for (const entry of entries) {
            const newSize = getContentSizeFromResizeObserverEntry(entry);
            if (!size || !isSizeEqual(size, newSize)) {
                size = newSize;
                callback(newSize);
            }
        }
    });
    resizeObserver.observe(element);
}
