import { useCallback, useState } from 'react';
export function useCounter({ initialValue = 0, min = 0, max = Infinity, shouldWrap = false } = {}) {
    const [value, _setValue] = useState(initialValue);
    const setValue = useCallback((value) => {
        _setValue(value > max
            ? shouldWrap
                ? min
                : max
            : value < min
                ? shouldWrap
                    ? max
                    : min
                : value);
    }, [shouldWrap, min, max, _setValue]);
    return {
        value,
        increment() {
            setValue(value + 1);
        },
        decrement() {
            setValue(value - 1);
        },
        setValue
    };
}
