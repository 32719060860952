import { isNullish } from '../isNullish';
import { negate } from '../negate';
import { pickBy } from '../pickBy';
/**
 * Creates an object composed of the object properties that are filled
 * (i.e., non-nullish).
 * @category Object
 * @param object The source object.
 * @returns The new object with filled (i.e., non-nullish) values.
 * @example
 * pickByFilled({ a: true, b: false, c: '', d: null, e: undefined })
 * // => { a: true, b: false, c: '' }
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function pickByFilled(object) {
    return pickBy(object, negate(isNullish));
}
