import { section } from '@account/amk-styles';
import React from 'react';
import { SectionGridSpacer } from '.';
import { GridColumn, GridRow } from '../..';
const [, element] = section;
export const SectionItem = ({ single, description, ...divProps }) => {
    const gridColumn = (React.createElement(React.Fragment, null,
        React.createElement(GridColumn, { large: "6", medium: "12" },
            React.createElement("div", { className: element('item', { description }), ...divProps })),
        description && React.createElement(SectionGridSpacer, null)));
    if (single)
        return (React.createElement(GridColumn, { large: "12" },
            React.createElement(GridRow, null, gridColumn)));
    return gridColumn;
};
