export function createApplyFormat(format, options = {}) {
    const { acceptedSuffix } = options;
    return function applyFormat(value, { wasBackspaceDetected = false } = {}) {
        const _format = [...format];
        const valueChars = value.split('');
        const result = [];
        let f;
        const saved = [];
        while ((f = _format.shift())) {
            const nextValueChar = valueChars[0];
            if (!nextValueChar) {
                if (!wasBackspaceDetected) {
                    flush();
                }
                _format.unshift(f);
                return done();
            }
            if (typeof f === 'string') {
                if (f !== nextValueChar) {
                    saved.push(f);
                    continue;
                }
                if (!wasBackspaceDetected) {
                    flush(true);
                    continue;
                }
                saved.push(valueChars.shift());
                continue;
            }
            if (f.test(nextValueChar)) {
                flush(true);
                continue;
            }
            valueChars.shift();
        }
        const remainingChars = valueChars.join('');
        if (acceptedSuffix) {
            for (let i = remainingChars.length; i > 0; i--) {
                const prefixOfRemaining = remainingChars.substr(0, i);
                if (acceptedSuffix.test(prefixOfRemaining)) {
                    return done(prefixOfRemaining);
                }
            }
        }
        return done();
        function flush(shouldConsumeValueCharacter = false) {
            if (shouldConsumeValueCharacter) {
                result.push(...saved, valueChars.shift());
            }
            else {
                result.push(...saved);
            }
            saved.length = 0;
        }
        function done(extraChars) {
            let r = result.join('');
            if (extraChars) {
                r += extraChars;
            }
            return [r, isValid(r)];
        }
        function isValid(value) {
            return format.every((f, i) => {
                const char = value[i];
                return typeof f === 'string' ? f === char : f.test(char);
            });
        }
    };
}
