import { noop } from '@account/typetanium';
import { useEffect, useRef } from 'react';
/**
 * This implementation is inspired by the example given by Dan Abramov.
 * @see https://github.com/donavon/use-interval
 **/
export function useInterval({ onTick, interval }) {
    const cachedCallback = useRef(noop);
    useEffect(() => {
        cachedCallback.current = onTick;
    }, [onTick]);
    useEffect(() => {
        let id = NaN;
        const tick = () => {
            cachedCallback.current();
        };
        if (interval > 0) {
            id = window.setInterval(tick, interval);
        }
        else {
            window.clearInterval(id);
        }
        return () => window.clearInterval(id);
    }, [interval]);
}
