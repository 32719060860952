/** @see https://developer.mozilla.org/en-US/docs/Web/CSS/font-weight#common_weight_name_mapping */
export var FontWeight;
(function (FontWeight) {
    /** Hairline */
    FontWeight[FontWeight["Thin"] = 100] = "Thin";
    /** Ultra light */
    FontWeight[FontWeight["ExtraLight"] = 200] = "ExtraLight";
    FontWeight[FontWeight["Light"] = 300] = "Light";
    /** Regular */
    FontWeight[FontWeight["Normal"] = 400] = "Normal";
    FontWeight[FontWeight["Medium"] = 500] = "Medium";
    /** Demi Bold */
    FontWeight[FontWeight["SemiBold"] = 600] = "SemiBold";
    FontWeight[FontWeight["Bold"] = 700] = "Bold";
    /** Ultra Bold */
    FontWeight[FontWeight["ExtraBold"] = 800] = "ExtraBold";
    /** Heavy */
    FontWeight[FontWeight["Black"] = 900] = "Black";
})(FontWeight || (FontWeight = {}));
