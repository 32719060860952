/**
 * Checks if `value` is `undefined`.
 * @param value The value to check.
 * @returns `true` if `value` is `undefined`; else, `false`.
 * @example
 * isUndefined(void 0)
 * // => true
 *
 * isUndefined(null)
 * // => false
 */
export function isUndefined(value) {
    return value === void 0;
}
