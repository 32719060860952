import { buttonBar } from '@account/amk-styles';
import React, { Children } from 'react';
import { Spinner } from '../Spinner';
const [block, element] = buttonBar;
const ButtonBarSide = ({ children }) => (React.createElement("div", { className: element('side') }, children));
export const ButtonBar = ({ children, centered = false, splitButtonsAt = 0, workingText = false, ...fieldsetProps }) => {
    const isWorking = Boolean(workingText);
    return (React.createElement("fieldset", { className: block({ working: isWorking }), ...fieldsetProps },
        React.createElement("div", { className: element('nav', { centered: isWorking || centered }) }, (() => {
            if (workingText) {
                return (React.createElement(React.Fragment, null,
                    React.createElement(Spinner, { axLabel: workingText }),
                    React.createElement("span", null, workingText)));
            }
            if (centered) {
                return children;
            }
            if (!Children.count(children)) {
                return;
            }
            if (!splitButtonsAt) {
                return React.createElement(ButtonBarSide, null, children);
            }
            const auxillaryButtons = Children.toArray(children);
            const lastButtons = auxillaryButtons.splice(splitButtonsAt);
            return (React.createElement(React.Fragment, null,
                React.createElement(ButtonBarSide, null, auxillaryButtons),
                React.createElement(ButtonBarSide, null, lastButtons)));
        })())));
};
