import { AMKClient } from '@account/amk-client'

export default class Amk {
  rootElem: HTMLElement | null
  constructor(elem: HTMLElement | null) {
    this.rootElem = elem
  }

  initializeClient(idmsUrl: string): AMKClient {
    return new AMKClient(idmsUrl, {
      autoResize: this.rootElem || undefined,
    })
  }
}
