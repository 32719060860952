import { LocStrings } from './i18n'

export interface ApiError {
  status?: number
  'status-message': string
  title?: string
}

export enum FAMILY_SERVICE_ID {
  APPLE_MUSIC = 'APPLE_MUSIC',
  APPLE_TV_PLUS = 'APPLE_TV_PLUS',
  ARCADE = 'ARCADE',
  NEWS_PLUS = 'NEWS_PLUS',
  SHARE_MY_LOCATION = 'SHARE_MY_LOCATION',
  PURCHASE_SHARING = 'PURCHASE_SHARING',
  ICLOUD_STORAGE = 'ICLOUD_STORAGE',
  APPLE_CASH = 'APPLE_CASH',
  ASK_TO_BUY = 'ASK_TO_BUY',
  SCREEN_TIME = 'SCREEN_TIME',
}

export interface ServiceGroupItem {
  id: FAMILY_SERVICE_ID
  isSubscriber: boolean
}

export interface FamilyMember {
  dsid: string
  firstName: string
  lastName: string
  fullName: string
  serviceGroups?: ServiceGroupItem[]
  isParent?: boolean
  ageClassification?: 'ADULT' | 'CHILD' | 'TEEN'
  appleIdForPurchases?: string
  appleId?: string
  altDsid?: string
  ageInYears?: number
  dsidForPurchases?: string
  joinDateEpoch?: number
  dateOfBirthEpoch?: number
}

export interface FamilyService {
  displayLabel: string
  icon: string
  id: FAMILY_SERVICE_ID
  // If this is true we show it in the add member sheet.
  isSubscription: boolean
}

export interface FamilyDetails {
  currentDsid: string // The user viewing the details.
  currentUserAppleId: string
  isLinkedToFamily: boolean
  isMemberOfFamily: boolean
  activeFamilyServices?: FamilyService[]
  family?: {
    familyId: string
    organizerDsid: string
  }
  familyMembers?: FamilyMember[]
}

type ApiHandler = ((e?: ApiError) => Promise<void>) | undefined
type RenewAuthTokenHandler = () => Promise<void>

export interface UserPhotoData {
  cropRectangle: string // "{x},{y},{width},{height}"
  imageData: string
  dsid: string
}

export interface I18nApiResponse {
  locStrings: LocStrings
  language: string
  isRtl: boolean
}

export interface AppConfigResponse {
  idmsUrl: string
}

const API_URLS = {
  APP_CONFIG: '/api/app-config?env=',
  FAMILY_MEMBERS: '/api/family-members',
  I18N: '/api/i18n',
  MEMBER_PHOTOS: '/api/member-photos',
  MEMBER_PHOTO: '/api/memberPhoto',
  UNLINK_FAMILY: '/api/unlink-family',
}

// Needs to be a special case because we can't initialize without this url.
export const getAppConfig = async (env:string): Promise<AppConfigResponse> => {
  const res = await fetch(API_URLS.APP_CONFIG.concat(env))
  return (res.json() as unknown) as AppConfigResponse
}

export default class Api {
  handleApiError: ApiHandler
  renewAuthToken: RenewAuthTokenHandler
  constructor({
    renewAuthToken,
    handleApiError,
  }: {
    renewAuthToken: RenewAuthTokenHandler
    handleApiError?: ApiHandler
  }) {
    this.handleApiError = handleApiError
    this.renewAuthToken = renewAuthToken
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async fetch({
    url,
    params,
    retryAttempts = 1,
  }: {
    url: RequestInfo
    params?: RequestInit
    retryAttempts?: number
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  }): Promise<any> {
    let res

    try {
      res = await fetch(url, params)
      // Retry this request after renewing the token
      if (res?.status === 401 && retryAttempts > 0) {
        await this.renewAuthToken()
        return this.fetch({ url, params, retryAttempts: 0 })
      }
    } catch (e) {
      console.error('fetch error', e)
    }

    if (!res || res.status >= 400) {
      let apiError
      try {
        // Parsing will fail if the server responds without a body
        apiError = await res?.json()
      } catch (e) {
        console.error(`Tried to parse json but failed. Input: ${url}`, e)
      }
      if (this.handleApiError) {
        this.handleApiError(apiError)
      }
    } else {
      return await res.json()
    }
  }

  async getFamilyDetails(): Promise<FamilyDetails> {
    return (await this.fetch({ url: API_URLS.FAMILY_MEMBERS })) as FamilyDetails
  }

  async getI18nInformation(): Promise<I18nApiResponse> {
    return (await this.fetch({ url: API_URLS.I18N })) as I18nApiResponse
  }

  async getMemberPhotos(): Promise<UserPhotoData[]> {
    return (await this.fetch({
      url: API_URLS.MEMBER_PHOTOS,
    })) as UserPhotoData[]
  }

  // TODO implement
  async unlinkAccount(postBody: {
    currentDsid: string
    familyId: string
    currentUserAppleId: string
  }): Promise<FamilyDetails> {
    return await this.fetch({
      url: API_URLS.UNLINK_FAMILY,
      params: {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(postBody),
      },
    })
  }
}
