import { ImageProps } from '@account/amk-components'
import { UserPhotoData } from './api'

export const toProfileImageData = (
  userPhotoData?: UserPhotoData
):
  | {
      cropRectangle: Required<ImageProps['cropRectangle']>
      imageData: string
    }
  | undefined => {
  if (userPhotoData) {
    const [left, top, width, height] = userPhotoData.cropRectangle
      .split(',')
      .map((x) => parseInt(x))
    return {
      imageData: userPhotoData.imageData,
      cropRectangle: { left, top, width, height },
    }
  }
}
