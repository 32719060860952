import I18n from "@lib/i18n";

export const getAgeLabel = (i18n: I18n, dob: Date): string => {
    const now = new Date()
    const diff = now.getTime() - dob.getTime();
    const millisInDay = 1000 * 60 * 60 * 24;

    const numberOfYears = Math.floor(diff / (millisInDay * 365));
    if (numberOfYears >= 1) {
        if (numberOfYears > 2) {
            return i18n.getLoc("com.apple.cloud.familyws.ageInYears.plural", {age: numberOfYears});
        } else if (numberOfYears == 2) {
            return i18n.getLoc("com.apple.cloud.familyws.ageInYears.two.plural", {age: numberOfYears});
        } else {
            return i18n.getLoc("com.apple.cloud.familyws.ageInYears.singular", {age: numberOfYears});
        }
    }

    const numberOfMonths = Math.floor(diff / (millisInDay * 30.44));
    if (numberOfMonths >= 1) {
        if (numberOfMonths > 2) {
            return i18n.getLoc("com.apple.cloud.familyws.ageInMonths.plural", {age: numberOfMonths});
        } else if (numberOfMonths == 2) {
            return i18n.getLoc("com.apple.cloud.familyws.ageInMonths.two.plural", {age: numberOfMonths});
        } else {
            return i18n.getLoc("com.apple.cloud.familyws.ageInMonths.singular", {age: numberOfMonths});
        }
    }

    const numberOfWeeks = Math.floor(diff / (millisInDay * 7));
    if (numberOfWeeks >= 1) {
        if (numberOfWeeks > 2) {
            return i18n.getLoc("com.apple.cloud.familyws.ageInWeeks.plural", {age: numberOfWeeks});
        } else if (numberOfWeeks == 2) {
            return i18n.getLoc("com.apple.cloud.familyws.ageInWeeks.two.plural", {age: numberOfWeeks});
        } else {
            return i18n.getLoc("com.apple.cloud.familyws.ageInWeeks.singular", {age: numberOfWeeks});
        }
    }
    const numberOfDays = Math.max(1, Math.floor(diff / millisInDay));
    if (numberOfDays > 2) {
        return i18n.getLoc("com.apple.cloud.familyws.ageInDays.plural", {age: numberOfDays});
    } else if (numberOfDays == 2) {
        return i18n.getLoc("com.apple.cloud.familyws.ageInDays.two.plural", {age: numberOfDays});
    } else {
        return i18n.getLoc("com.apple.cloud.familyws.ageInDays.singular", {age: numberOfDays});
    }
};

export const getDateString = (date: Date, locale: string) => {
    if (locale.toLowerCase() == "ar-sa") {
        return date.toLocaleDateString(locale, {calendar: 'gregory'});
    }
    return date.toLocaleDateString(locale)
}

export const getQueryParamValue = (keyName: string) => {
  let search = window.location.search;
  let params = new URLSearchParams(search);
  return params.get(keyName);
}