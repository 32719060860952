import { useUID } from '@account/react-hooks';
import { pickByFilled, pickByTruthy } from '@account/typetanium';
import classnames from 'classnames';
import React, { forwardRef, useState } from 'react';
import { bem, UtilityClass } from '../../utils';
import { getIsError } from '../../utils/getIsError';
import { FormMessage } from '../FormMessage';
import './TextArea.scss';
const [textboxBlock, textboxElement] = bem('form-textbox');
const [textareaBlock] = bem('form-textarea');
const _TextArea = ({ 'aria-describedby': ariaDescribedBy, children, errorMessage, id: _id, isError: _isError, labelText, value, placeholder, disabled, onFocus, onBlur, ...textareaProps }, ref) => {
    const id = useUID({ defaultValue: _id, prefix: textareaBlock() + '-' });
    const isError = getIsError({ isError: _isError, errorMessage });
    const errorId = (errorMessage === null || errorMessage === void 0 ? void 0 : errorMessage.children) && isError ? `${id}_error` : '';
    const [isFocused, setIsFocused] = useState(false);
    const onBlurHandler = (ev) => {
        setIsFocused(false);
        onBlur === null || onBlur === void 0 ? void 0 : onBlur(ev);
    };
    const onFocusHandler = (ev) => {
        setIsFocused(true);
        onFocus === null || onFocus === void 0 ? void 0 : onFocus(ev);
    };
    return (React.createElement("div", { className: classnames(textboxBlock(), {
            [UtilityClass.IsError]: isError
        }) },
        ' ' /* fix Safari VO editing bug in Mojave <rdar://problem/62887454> */,
        React.createElement("div", { className: classnames(textareaBlock({ 'with-placeholder': !!placeholder }), {
                disabled,
                focused: isFocused,
                'form-textbox-entered': value
            }) },
            React.createElement("textarea", { ...pickByTruthy({
                    'aria-describedby': classnames(errorId, ariaDescribedBy),
                    'aria-invalid': isError
                }), ...pickByFilled({
                    value,
                    placeholder,
                    disabled
                }), autoCorrect: "off", autoCapitalize: "off", id: id, ref: ref, spellCheck: false, ...textareaProps, onFocus: onFocusHandler, onBlur: onBlurHandler })),
        React.createElement("label", { "aria-hidden": true, className: textboxElement('label'), htmlFor: id }, labelText),
        children,
        isError && (errorMessage === null || errorMessage === void 0 ? void 0 : errorMessage.children) && (React.createElement(FormMessage, { id: errorId, ...errorMessage }))));
};
export const TextArea = forwardRef(_TextArea);
