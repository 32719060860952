/**
 * Sleeps for a specified `duration`.
 * @param duration Time in milliseconds to sleep.
 * @category Util
 * @returns A `Promise` that resolves when the specified `duration` has ellapsed.
 * @example
 * async function brb() {
 *   console.log('Be back in 42 seconds...')
 *   await sleep(42000)
 *   console.log("OK, I'm back!")
 * }
 */
export async function sleep(duration) {
    return new Promise((resolve) => {
        setTimeout(resolve, duration);
    });
}
