import React from 'react';
import { tooltip } from './bemTooltip';
import './Tooltip.scss';
const [block] = tooltip;
export const TooltipContainer = ({ children, show, validation = false, position }) => (React.createElement("div", { className: block({
        show,
        validation,
        before: position === 'before',
        after: position === 'after'
    }) }, children));
