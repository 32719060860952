/**
 * wraps a function
 * @category Function
 * @returns the wrapped function
 * @example
 * const square = (x: number) => x ** 2;
 * const hypotenuse = wrap(
 *   square,
 *   'hypotenuse',
 *   (a: number, b: number) => square(a) + square(b)
 * );
 * hypotenuse(2, 3)
 * // => 13
 */
export function wrap(predicate, wrapperName, callback) {
    return Object.defineProperty(callback, 'name', {
        configurable: true,
        value: `${wrapperName}(${predicate.name || 'anonymous'})`
    });
}
