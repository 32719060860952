import { modal } from '@account/amk-styles';
import { VOICEOVER_FOCUS_WAITTIME } from '@account/react-hooks';
import { sleep } from '@account/typetanium';
import { useEffect, useRef } from 'react';
import { disableElement, focusableElementsSelector, getAllSiblings, restoreElement } from '../axUtils';
const [block] = modal;
const bodyClassNameWhenOpen = block({ open: true }).substr(block().length + 1);
let globalOpenModalCount = 0;
const updateBodyOpenClass = () => {
    if (globalOpenModalCount > 0) {
        if (!document.body.classList.contains(bodyClassNameWhenOpen)) {
            document.body.classList.add(bodyClassNameWhenOpen);
        }
    }
    else {
        document.body.classList.remove(bodyClassNameWhenOpen);
    }
};
export const useModalAccessibilityEffects = ({ autoFocus = true, focusElementId, visible, modalId }) => {
    const axElementsRef = useRef(new Map());
    const needsAccessibilityCleanupRef = useRef(false);
    const anchorElementRef = useRef(null);
    const cleanupAccessibility = () => {
        var _a, _b;
        if (needsAccessibilityCleanupRef.current) {
            globalOpenModalCount--;
            updateBodyOpenClass();
            const siblings = (_a = axElementsRef.current.get(`${modalId}-siblings`)) !== null && _a !== void 0 ? _a : [];
            for (const [index, element] of siblings.entries()) {
                restoreElement(element, axElementsRef.current, `${modalId}-${index}`);
            }
            if (autoFocus) {
                (_b = anchorElementRef.current) === null || _b === void 0 ? void 0 : _b.focus();
            }
            axElementsRef.current.clear();
            anchorElementRef.current = null;
            needsAccessibilityCleanupRef.current = false;
        }
    };
    useEffect(() => {
        var _a, _b, _c;
        if (visible) {
            globalOpenModalCount++;
            updateBodyOpenClass();
            const siblings = getAllSiblings(document.getElementById(modalId)).filter(
            // siblings already aria hidden are most likely already handle by a different Modal
            (sibling) => sibling.getAttribute('aria-hidden') !== 'true');
            if (siblings.length) {
                axElementsRef.current.set(`${modalId}-siblings`, siblings);
                for (const [index, sibling] of siblings.entries()) {
                    disableElement(sibling, axElementsRef.current, `${modalId}-${index}`);
                }
            }
            /* Find focusable Element by ID, (if not found) try to focus on the first focusable element in the Modal */
            const focusableElement = (_a = document.getElementById(focusElementId !== null && focusElementId !== void 0 ? focusElementId : '')) !== null && _a !== void 0 ? _a : (_c = (_b = document
                .getElementById(modalId)) === null || _b === void 0 ? void 0 : _b.querySelectorAll(focusableElementsSelector)) === null || _c === void 0 ? void 0 : _c[0];
            sleep(VOICEOVER_FOCUS_WAITTIME).then(() => {
                anchorElementRef.current = document.activeElement;
                if (autoFocus) {
                    focusableElement === null || focusableElement === void 0 ? void 0 : focusableElement.focus();
                }
            });
            needsAccessibilityCleanupRef.current = true;
        }
        else {
            cleanupAccessibility();
        }
        return () => {
            cleanupAccessibility();
        };
    }, [visible]);
    return { axElements: axElementsRef.current };
};
