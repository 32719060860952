/**
 * @see https://github.pie.apple.com/aos-ui/ac-forms/blob/master/src/scss/ac-forms/modules/form-icons/_core.scss
 */
export var FormIconGlyph;
(function (FormIconGlyph) {
    FormIconGlyph["Add19"] = "add19";
    FormIconGlyph["Add29"] = "add29";
    FormIconGlyph["CheckAlt15"] = "checkalt15";
    FormIconGlyph["CheckAlt19"] = "checkalt19";
    FormIconGlyph["Circle15"] = "circle15";
    FormIconGlyph["Circle19"] = "circle19";
    FormIconGlyph["ClearSolid15"] = "clearsolid15";
    FormIconGlyph["ClearSolid19"] = "clearsolid19";
    FormIconGlyph["Info19"] = "info19";
    FormIconGlyph["Location"] = "location";
    FormIconGlyph["Remove19"] = "remove19";
    FormIconGlyph["Reset"] = "reset";
    FormIconGlyph["Search15"] = "search15";
    FormIconGlyph["Search19"] = "search19";
    /** Use on `<a>` element to display fill icon. */
    FormIconGlyph["Unvalidated19"] = "unvalidated19";
    FormIconGlyph["UnvalidatedAlt15"] = "unvalidatedalt15";
    /** Use on `<a>` element to display non-fill icon. */
    FormIconGlyph["UnvalidatedAlt19"] = "unvalidatedalt19";
})(FormIconGlyph || (FormIconGlyph = {}));
