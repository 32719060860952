import { useCallback } from 'react';
import { useGlobalEvent } from './useGlobalEvent';
export function useOutsideEvent(node, type, listener, options) {
    const filteredListener = useCallback((event) => {
        if (!node || node.contains(event.target)) {
            return;
        }
        listener(event);
    }, [node, listener]);
    useGlobalEvent(type, filteredListener, options);
}
