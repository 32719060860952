import React, { ReactElement, useContext, useState, useEffect } from 'react'
import {
  Button,
  Card,
  Section,
  SectionGrid,
  SectionHeader,
  SectionItem,
  SectionTitle,
  DetailsText,
  ProfileImage,
} from '@account/amk-components'
import { FamilyDetails, FamilyMember, UserPhotoData } from '@lib/api'
import { AMKClient } from '@account/amk-client'
import I18nContext from '@components/i18n-context'
import { toProfileImageData } from '@lib/profile-image'

const LinkedAccount = ({
  organizer,
  onConfirm,
}: {
  organizer?: FamilyMember
  onConfirm: () => Promise<unknown>
}): ReactElement | null => {
  const i18n = useContext(I18nContext)

  if (!i18n || !organizer) {
    return null
  }

  return (
    <Card
      theme="banner"
      title={i18n.getLoc('com.apple.cloud.familyws.unlink.title')}
      lines={[
        i18n.getLoc(
          'com.apple.cloud.familyws.unlink.paragraph.1',
          i18n.getNameReplacements(organizer)
        ),
        '',
        <Button key="disconnectaccount" color="secondary" onClick={onConfirm}>
          {i18n.getLoc('com.apple.cloud.familyws.unlink.button.confirm')}
        </Button>,
      ]}
    />
  )
}

const BlankState = (): ReactElement | null => {
  const i18n = useContext(I18nContext)

  if (!i18n) {
    return null
  }

  return (
    <Card
      theme="banner"
      title={i18n.getLoc('com.apple.cloud.familyws.no.family.info.card.title')}
      lines={[
        <div
          style={{
            color: '#1D1D1F',
          }}
          key="p1"
        >
          {i18n.getLoc(
            'com.apple.cloud.familyws.common.continueondevice.paragraph.1.ios'
          )}
        </div>,
        i18n.getLoc(
          'com.apple.cloud.familyws.common.continueondevice.paragraph.2.generic.ios'
        ),
        <div
          style={{
            color: '#1D1D1F',
          }}
          key="p3"
        >
          {i18n.getLoc(
            'com.apple.cloud.familyws.common.continueondevice.paragraph.1.macos'
          )}
        </div>,
        i18n.getLoc(
          'com.apple.cloud.familyws.common.continueondevice.paragraph.2.macos.v2'
        ),
      ]}
    />
  )
}

const FamilyMembers = ({
  familyDetails,
  amkClient,
  memberPhotos,
}: {
  amkClient: AMKClient
  familyDetails: FamilyDetails | undefined
  memberPhotos: UserPhotoData[]
}): ReactElement | null => {
  const i18n = useContext(I18nContext)
  // This might get updated on unlink
  const [familyDetailsState, setFamilyDetailsState] = useState(familyDetails)

  useEffect(() => {
    if (familyDetails !== familyDetailsState) {
      setFamilyDetailsState(familyDetails)
    }
  }, [familyDetails])

  if (!i18n || !familyDetailsState) {
    return null
  }

  const { isMemberOfFamily, isLinkedToFamily, currentDsid } = familyDetailsState
  if (!isMemberOfFamily && !isLinkedToFamily) {
    return <BlankState />
  }

  const organizerDsid = familyDetailsState.family?.organizerDsid
  const familyMembers = familyDetailsState.familyMembers || []
  const organizer = familyMembers.find(
    (member) => organizerDsid !== null && member.dsid === organizerDsid
  )

  return !isMemberOfFamily ? (
    <LinkedAccount
      organizer={organizer}
      onConfirm={async () => {
        const updatedFamilyData = await amkClient.openModule(
          'family-unlink',
          {}
        )
        if (updatedFamilyData) {
          setFamilyDetailsState(updatedFamilyData as FamilyDetails)
        }
      }}
    />
  ) : (
    <Section embedded>
      <SectionHeader>
        <SectionTitle>
          {i18n.getLoc('com.apple.cloud.familyws.members.list.title')}
        </SectionTitle>
      </SectionHeader>
      <SectionGrid>
        {familyMembers.map((member: FamilyMember) => {
          const { dsid } = member
          const isSelf = currentDsid === dsid
          const nameLocKey = isSelf
            ? 'com.apple.cloud.familyws.members.name.me'
            : 'com.apple.cloud.familyws.members.name'
          const photoData = memberPhotos.find(
            (photoData) => photoData.dsid === dsid
          )
          return (
            <SectionItem key={dsid}>
              <Button
                bare
                expand
                onClick={() => {
                  amkClient.openModule('family-memberDetails', { dsid })
                }}
              >
                <Card
                  theme="module"
                  title={i18n.getLoc(
                    nameLocKey,
                    i18n.getNameReplacements(member)
                  )}
                  minLines="3"
                  lines={[i18n.getRoleSubtitle({ member, organizerDsid, addAgePrefix: true })]}
                  rightElements={[
                    <ProfileImage
                      size={40}
                      key="icon"
                      profileImage={toProfileImageData(photoData)}
                      alt="Profile Image"
                    />,
                  ]}
                />
              </Button>
            </SectionItem>
          )
        })}
      </SectionGrid>
    </Section>
  )
}

export default FamilyMembers
