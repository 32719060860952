const messagePrefix = 'winio:';
export const encode = (namespace, data) => {
    return `${messagePrefix}${namespace}${JSON.stringify(data)}`;
};
export const decode = (namespace, message) => {
    const body = message.substring(messagePrefix.length + namespace.length);
    return JSON.parse(body);
};
export const checkIsValidMessage = (namespace, message) => {
    return (typeof message === 'string' &&
        message.startsWith(messagePrefix) &&
        message.substr(messagePrefix.length, namespace.length) === namespace);
};
