import { card } from '@account/amk-styles';
import { Icon, IconGlyph } from '@account/standards-components';
import React from 'react';
import { Button } from '../Button';
const [block, element] = card;
export const Card = ({ background, theme, border = true, close, info = false, rounded = true, shadow = true, minLines, noWrap = false, ...props }) => {
    const blockClassNames = block({
        ...(theme
            ? { [`theme-${theme}`]: true }
            : background
                ? { [background]: true }
                : { info }),
        border,
        'no-wrap': noWrap,
        padding: Boolean(props.children),
        rounded,
        shadow,
        [`min-lines-${minLines}`]: minLines
    });
    const closeButton = close && (React.createElement("div", { className: element('close') },
        React.createElement(Button, { "aria-label": close.axLabel, "data-testid": "card-close-button", iconOnly: true, onClick: close.onClick },
            React.createElement(Icon, { glyph: IconGlyph.Close }))));
    {
        const { children, ...divProps } = props;
        if (children) {
            if (divProps.title) {
                throw new Error('cannot use title when children are specified');
            }
            return (React.createElement("div", { className: blockClassNames, ...divProps },
                closeButton,
                children));
        }
    }
    {
        const { lines, leftElements, rightElements, title, heading: _heading, ...divProps } = props;
        const heading = typeof title === 'string'
            ? typeof _heading === 'undefined'
                ? 3
                : _heading
            : false;
        return (React.createElement("div", { className: blockClassNames, ...divProps },
            closeButton,
            leftElements && (React.createElement("div", { className: element('left') }, leftElements.map((leftElement, i) => (React.createElement("div", { key: i, className: element('left-element') }, leftElement))))),
            React.createElement("div", { className: element('middle') },
                React.createElement("div", { className: element('top') },
                    React.createElement("div", { className: element('header') }, heading ? (React.createElement(`h${heading}`, {
                        className: element('title'),
                        children: title
                    })) : (React.createElement("div", { className: element('title') }, title))),
                    lines && (React.createElement("div", { className: element('body') }, lines.map((line, i) => (React.createElement("div", { key: i, className: element('line') }, line || '\u00A0'))))))),
            rightElements && (React.createElement("div", { className: element('right') }, rightElements.map((rightElement, i) => (React.createElement("div", { key: i, className: element('right-element') }, rightElement)))))));
    }
};
