const activityEventNames = [
    'keypress',
    'keydown',
    'click',
    'mousemove',
    'scroll',
    'touchmove',
    'touchstart'
];
let lastActiveTime = Date.now();
const updateLastActiveTime = () => {
    lastActiveTime = Date.now();
};
activityEventNames.forEach((event) => {
    window.addEventListener(event, updateLastActiveTime, false);
});
export const onIdle = (onIdleHandler, { interval = 10000, threshold = 60000 } = {}) => {
    let isIdle = false;
    const intervalId = setInterval(() => {
        const newIsIdle = Date.now() - lastActiveTime >= threshold;
        if (isIdle !== newIsIdle) {
            isIdle = newIsIdle;
            onIdleHandler(isIdle);
        }
    }, interval);
    return () => clearInterval(intervalId);
};
