const tabIndexDataAttribute = 'data-tabindex';
export const tabIndexSelector = '[tabindex]';
export const focusableElementsSelector = [
    'button',
    '[href]',
    'input',
    'select',
    'textarea',
    tabIndexSelector
].join(',');
export function getAllSiblings(element) {
    var _a, _b;
    return [...((_b = (_a = element === null || element === void 0 ? void 0 : element.parentNode) === null || _a === void 0 ? void 0 : _a.children) !== null && _b !== void 0 ? _b : [])].filter((child) => child !== element && child.tagName !== 'SCRIPT');
}
export function saveTabIndexAttribute(tabIndexElements) {
    for (const element of tabIndexElements) {
        const tabIndex = element.getAttribute('tabIndex');
        if (tabIndex) {
            element.setAttribute(tabIndexDataAttribute, tabIndex);
        }
        else {
            element.removeAttribute('tabindex');
        }
    }
}
export function restoreTabIndexAttribute(elements = []) {
    for (const element of elements) {
        const tabIndex = element.getAttribute(tabIndexDataAttribute);
        if (tabIndex) {
            element.setAttribute('tabindex', tabIndex);
            element.removeAttribute(tabIndexDataAttribute);
        }
    }
}
export function disableFocusOnElements(elements = []) {
    for (const element of elements) {
        element.setAttribute('tabindex', '-1');
    }
}
export function restoreFocusOnElements(elements = []) {
    for (const element of elements) {
        if (element.getAttribute('tabindex') === '-1') {
            element.removeAttribute('tabindex');
        }
    }
}
export function disableElement(element, axElements, keyPrefix) {
    // Set aria-hidden true
    element.setAttribute('aria-hidden', 'true');
    // Save tabIndex attribute for child elements with tabIndex
    const tabIndexElements = element.querySelectorAll(tabIndexSelector);
    saveTabIndexAttribute(tabIndexElements);
    axElements.set(`${keyPrefix}-tabIndexElements`, tabIndexElements);
    // Disable Focus on all focusable child elements
    const focusableElements = element.querySelectorAll(focusableElementsSelector);
    disableFocusOnElements(focusableElements);
    axElements.set(`${keyPrefix}-focusableElements`, focusableElements);
}
export function restoreElement(element, axElements, keyPrefix) {
    element.removeAttribute('aria-hidden');
    // Restore Focus
    restoreFocusOnElements(axElements.get(`${keyPrefix}-focusableElements`));
    // Restore TabIndexAttribute
    restoreTabIndexAttribute(axElements.get(`${keyPrefix}-tabIndexElements`));
}
