import React, { ReactElement, useContext } from 'react'
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalHero,
  ModalTitle,
  DetailsText,
  ModalSubtitle,
  HeaderText,
  BodyText,
  CardList,
  ListItemChicletCard,
  ListItemCard,
  BannerCard,
  ProfileImage,
} from '@account/amk-components'
import {
  FamilyDetails,
  FamilyMember,
  FamilyService,
  FAMILY_SERVICE_ID,
  UserPhotoData,
} from '@lib/api'
import { useLocation } from 'react-router-dom'
import { AMKClient } from '@account/amk-client'
import CreditCardSymbol from '@apple/symbols/regular/creditcard.svg'
import DollarSignSymbol from '@apple/symbols/regular/dollarsign.circle.svg'
import HourglassSymbol from '@apple/symbols/regular/hourglass.svg'
import FindMySymbol from '@apple/symbols/regular/findmy.svg'
import AppStoreSymbol from '@apple/symbols/regular/appstore.svg'
import I18nContext from '@components/i18n-context'
import styles from './styles.module.scss'
import { toProfileImageData } from '@lib/profile-image'
import {getDateString} from "@lib/util";

interface ServiceWithSubscriberInfo extends FamilyService {
  isSubscriber?: boolean
}

export interface MemberDetailsProps {
  familyDetails: FamilyDetails | undefined
  amkClient: AMKClient
  memberPhotos: UserPhotoData[]
}

const findMember = (
  memberDsid: string | null,
  familyMembers: FamilyMember[] = []
): FamilyMember | undefined => {
  return familyMembers.find((member) => {
    return !!memberDsid && member.dsid === memberDsid
  })
}

const Loading = ({ amkClient }: { amkClient: AMKClient }) => {
  const i18n = useContext(I18nContext)
  return (
    <Modal
      visible
      axLabel={
        i18n?.getLoc('mme.setupservice.family.v2.common.loadingWithElipsis') ||
        ''
      }
      axWorkingLabel={i18n?.getLoc(
        'mme.setupservice.family.v2.common.loadingWithElipsis'
      )}
      onDismiss={() => {
        amkClient.hide()
      }}
      axCloseButtonLabel="Close"
    ></Modal>
  )
}

const MemberDetails = ({
  familyDetails,
  amkClient,
  memberPhotos,
}: MemberDetailsProps): ReactElement | null => {
  const i18n = useContext(I18nContext)
  const memberDsid = new URLSearchParams(useLocation().search).get('dsid')

  if (!familyDetails || !familyDetails?.family || !i18n) {
    return <Loading amkClient={amkClient} />
  }

  const {
    currentDsid,
    activeFamilyServices,
    familyMembers,
    family: { organizerDsid },
  } = familyDetails

  const shownMember = findMember(memberDsid, familyMembers)
  const organizer = findMember(organizerDsid, familyMembers)
  const currentUser = findMember(currentDsid, familyMembers)

  if (!shownMember || !organizer || !currentUser) {
    return <Loading amkClient={amkClient} />
  }

  const {
    fullName: shownMemberFullName,
    ageClassification: shownMemberAgeClassification,
    appleId,
    appleIdForPurchases,
    dsid,
    serviceGroups,
    joinDateEpoch,
  } = shownMember
  const isViewingSelf = memberDsid === currentDsid
  const isOrganizer = !!organizerDsid && organizerDsid === currentDsid

  const isSplitAccount = appleId !== appleIdForPurchases
  const memberServicesAndUndefined: (ServiceWithSubscriberInfo | undefined)[] =
    activeFamilyServices && serviceGroups
      ? serviceGroups.map((memberServiceItem) => {
          const { id, isSubscriber } = memberServiceItem
          const foundService = activeFamilyServices.find(
            (familyService) => id === familyService.id
          )
          return foundService ? { ...foundService, isSubscriber } : undefined
        })
      : []
  // TS complains unless I cast here.
  const memberServices: ServiceWithSubscriberInfo[] = memberServicesAndUndefined.filter(
    (elem) => elem !== undefined
  ) as ServiceWithSubscriberInfo[]
  const memberSubscriptions = memberServices.filter((s) => s.isSubscription)
  const memberFeatures = memberServices.filter((s) => !s.isSubscription)
  const joinDate = joinDateEpoch ? new Date(joinDateEpoch) : null
  const roleOrAgeTitle =
    shownMemberAgeClassification === 'ADULT'
      ? i18n.getLoc('com.apple.cloud.familyws.member.details.role.title')
      : i18n.getLoc('com.apple.cloud.familyws.member.details.age.title')
  const isSelf = currentDsid === dsid
  const nameLocKey = isSelf
    ? 'com.apple.cloud.familyws.members.name.me'
    : 'com.apple.cloud.familyws.members.name'
  const accountDescriptionLocKey = isSelf
    ? 'com.apple.cloud.familyws.member.details.split.account.description.purchases.me'
    : 'com.apple.cloud.familyws.member.details.split.account.description.purchases'
  const subscriptionListLocKey = isSelf
    ? 'com.apple.cloud.familyws.member.details.subscriptions.list.description.me'
    : 'com.apple.cloud.familyws.member.details.subscriptions.list.description'
  const featuresListLocKey = isSelf
    ? 'com.apple.cloud.familyws.member.details.features.list.description.me'
    : 'com.apple.cloud.familyws.member.details.features.list.description'
  const manageAccountLocKey = isSelf
    ? 'com.apple.cloud.familyws.member.details.manage.info.card.title.me'
    : 'com.apple.cloud.familyws.member.details.manage.info.card.title'
  const photoData = memberPhotos.find((photoData) => photoData.dsid === dsid)

  let managingMember: FamilyMember | undefined
  const isParentViewingChild =
    currentUser.isParent && shownMemberAgeClassification === 'CHILD'
  if (isViewingSelf) {
    managingMember = shownMember
  } else if (isOrganizer) {
    managingMember = organizer
  } else if (isParentViewingChild) {
    managingMember = currentUser
  }

  return (
    <Modal
      axLabel={shownMemberFullName}
      axCloseButtonLabel="close"
      visible
      onDismiss={() => {
        amkClient.hide()
      }}
    >
      <ModalContent>
        <ModalHeader>
          <ModalHero>
            <ProfileImage
              size={54}
              key="icon"
              profileImage={toProfileImageData(photoData)}
              alt="Profile Image"
            />
          </ModalHero>
          <ModalTitle>
            {i18n.getLoc(nameLocKey, i18n.getNameReplacements(shownMember))}
          </ModalTitle>
          {joinDate != null && <ModalSubtitle>
            {i18n.getLoc(
                'com.apple.cloud.familyws.member.details.header.description',
                {formattedDate: getDateString(joinDate, i18n.language)}
            )}
          </ModalSubtitle>
          }
        </ModalHeader>
        <ModalBody>
          <HeaderText heading="3">{roleOrAgeTitle}</HeaderText>
          <BodyText>
            {i18n.getRoleSubtitle({
              member: shownMember,
              organizerDsid,
              addAgePrefix: false
            })}
          </BodyText>

          <HeaderText heading="3">
            {isSplitAccount
              ? i18n.getLoc(
                  'com.apple.cloud.familyws.member.details.split.account.title.appleid'
                )
              : i18n.getLoc(
                  'com.apple.cloud.familyws.member.details.account.title'
                )}
          </HeaderText>
          <BodyText>{appleId}</BodyText>
          {isSplitAccount ? null : (
            <DetailsText>
              {i18n.getLoc(
                accountDescriptionLocKey,
                i18n.getNameReplacements(shownMember)
              )}
            </DetailsText>
          )}

          {isSplitAccount ? (
            <>
              <HeaderText heading="3">
                {i18n.getLoc(
                  'com.apple.cloud.familyws.member.details.split.account.title.purchases'
                )}
              </HeaderText>
              <BodyText>{appleIdForPurchases}</BodyText>
            </>
          ) : null}
          {memberSubscriptions.length > 0 ? (
            <>
              <HeaderText heading="3">
                {i18n.getLoc(
                  'com.apple.cloud.familyws.member.details.subscriptions.list.title'
                )}
              </HeaderText>
              <DetailsText>
                {i18n.getLoc(
                  subscriptionListLocKey,
                  i18n.getNameReplacements(shownMember)
                )}
              </DetailsText>
              <CardList>
                {memberSubscriptions.map((service) => {
                  const { id, icon, displayLabel, isSubscriber } = service
                  return (
                    <ListItemChicletCard
                      key={id}
                      // rdar://84311144 (LOC: AR-SA: CWA: Family: Shared Subscriptions (iPhone): Layout: Flipping Parenthesis.)
                      // We need to wrap in a bdi tag in case the display label is in LTR for an RTL language.
                      title={<bdi>{displayLabel}</bdi>}
                      leftElements={[
                        <div key={`icon-${id}`} className={styles.chiclet}>
                          <img src={icon} />
                        </div>,
                      ]}
                      rightElements={[
                        isSubscriber
                          ? i18n.getLoc(
                              'com.apple.cloud.familyws.member.details.subscriptions.list.item.subscriber'
                            )
                          : null,
                      ]}
                    />
                  )
                })}
              </CardList>
            </>
          ) : null}
          {memberFeatures.length > 0 ? (
            <>
              <HeaderText heading="3">
                {i18n.getLoc(
                  'com.apple.cloud.familyws.member.details.features.list.title'
                )}
              </HeaderText>
              <DetailsText>
                {i18n.getLoc(
                  featuresListLocKey,
                  i18n.getNameReplacements(shownMember)
                )}
              </DetailsText>
              <CardList>
                {memberFeatures.map((feature) => {
                  const { id, displayLabel } = feature

                  let symbol
                  switch (id) {
                    case FAMILY_SERVICE_ID.PURCHASE_SHARING:
                      symbol = (
                        <CreditCardSymbol
                          desiredFontSize={13}
                          layoutMode="glyphBox"
                        />
                      )
                      break
                    case FAMILY_SERVICE_ID.APPLE_CASH:
                      symbol = (
                        <DollarSignSymbol
                          desiredFontSize={13}
                          layoutMode="glyphBox"
                        />
                      )
                      break
                    case FAMILY_SERVICE_ID.SHARE_MY_LOCATION:
                      symbol = (
                        <FindMySymbol
                          desiredFontSize={13}
                          layoutMode="glyphBox"
                        />
                      )
                      break
                    case FAMILY_SERVICE_ID.ASK_TO_BUY:
                      symbol = (
                        <AppStoreSymbol
                          desiredFontSize={13}
                          layoutMode="glyphBox"
                        />
                      )
                      break
                    case FAMILY_SERVICE_ID.SCREEN_TIME:
                      symbol = (
                        <HourglassSymbol
                          desiredFontSize={13}
                          layoutMode="glyphBox"
                        />
                      )
                      break
                  }

                  return (
                    <ListItemCard
                      key={id}
                      title={displayLabel}
                      leftElements={[
                        <div
                          className={styles.glyphListItemIcon}
                          key={`symbol-${id}`}
                        >
                          {symbol}
                        </div>,
                      ]}
                    />
                  )
                })}
              </CardList>
            </>
          ) : null}
          {managingMember ? (
            <BannerCard
              title={i18n.getLoc(
                manageAccountLocKey,
                i18n.getNameReplacements(shownMember)
              )}
              lines={[
                <DetailsText color="glyph-gray" key="p1">
                  {i18n.getLoc(
                    'com.apple.cloud.familyws.common.continueondevice.paragraph.1.ios'
                  )}
                </DetailsText>,
                i18n.getLoc(
                  'com.apple.cloud.familyws.common.continueondevice.paragraph.2.ios',
                  i18n.getNameReplacements(managingMember)
                ),
                <DetailsText color="glyph-gray" key="p3">
                  {i18n.getLoc(
                    'com.apple.cloud.familyws.common.continueondevice.paragraph.1.macos'
                  )}
                </DetailsText>,
                i18n.getLoc(
                  'com.apple.cloud.familyws.common.continueondevice.paragraph.2.macos.v2'
                ),
              ]}
            />
          ) : null}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default MemberDetails
