import { TextField } from '@account/standards-components';
import { pickByFilled } from '@account/typetanium';
import React, { forwardRef, useCallback, useMemo, useState } from 'react';
import { createApplyFormat, escapeRegularExpression } from '../../helpers';
const shortestPhoneNumberLengthInTheWorld = 4;
const _PhoneField = ({ acceptedSuffix, autoComplete = 'tel', format, onChange, onKeyDown, onKeyUp, shouldShowCheckMarkWhenValid = true, ...textFieldProps }, ref) => {
    const [minLength, pattern, applyFormat] = useMemo(() => {
        const [formatParts, xCount] = splitFormat(format);
        const minLength = xCount || shortestPhoneNumberLengthInTheWorld;
        const pattern = format
            ? escapeRegularExpression(format).replace(/x/g, '\\d')
            : `\\d{${minLength},}${acceptedSuffix ? acceptedSuffix.source.replace(/^\^/, '') : ''}`;
        return [
            minLength,
            pattern,
            formatParts
                ? createApplyFormat(formatParts, { acceptedSuffix })
                : createApplyFormat(new Array(minLength).fill(/\d/), {
                    acceptedSuffix: acceptedSuffix !== null && acceptedSuffix !== void 0 ? acceptedSuffix : /^\d*$/
                })
        ];
    }, [acceptedSuffix, format]);
    const [isValid, setIsValid] = useState(false);
    const [wasBackspaceDetected, setWasBackspaceDetected] = useState(false);
    const _onChange = useCallback((event) => {
        if (format) {
            const t = event.target;
            const [result, _isValid] = applyFormat(t.value, {
                wasBackspaceDetected
            });
            t.value = result;
            setIsValid(_isValid);
        }
        onChange === null || onChange === void 0 ? void 0 : onChange(event);
    }, [format, onChange, wasBackspaceDetected]);
    const _onKeyDown = useCallback((event) => {
        setWasBackspaceDetected(event.key === 'Backspace');
        onKeyDown === null || onKeyDown === void 0 ? void 0 : onKeyDown(event);
    }, []);
    const _onKeyUp = useCallback((event) => {
        setWasBackspaceDetected(false);
        onKeyUp === null || onKeyUp === void 0 ? void 0 : onKeyUp(event);
    }, []);
    return (React.createElement(TextField, { autoCorrect: "off", inputMode: "tel", onChange: _onChange, onKeyDown: _onKeyDown, onKeyUp: _onKeyUp, type: "tel", ltr: true, ...pickByFilled({
            pattern
        }), ...{
            autoComplete,
            isValid: isValid && shouldShowCheckMarkWhenValid,
            minLength,
            ref,
            ...textFieldProps
        } }));
};
function splitFormat(format) {
    let xCount = 0;
    return [
        format === null || format === void 0 ? void 0 : format.split('').map((char) => {
            if (char === 'x') {
                xCount++;
                return /\d/;
            }
            return char;
        }),
        xCount
    ];
}
export const PhoneField = forwardRef(_PhoneField);
