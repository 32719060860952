import React from 'react';
import classnames from 'classnames';
/**
 * @see https://standards.apple.com/element-library/#pattern-icons
 */
export const Icon = ({ glyph, weight, ...props }) => {
    var _a;
    if (weight) {
        /* istanbul ignore next */
        (props.style = (_a = props.style) !== null && _a !== void 0 ? _a : {}).fontWeight = weight;
    }
    return React.createElement("span", { className: classnames('icon', `icon-${glyph}`), ...props });
};
