import { useCounter, useGlobalEvent, useUID } from '@account/react-hooks';
import { isUndefined, kebabCase, pickByTruthy } from '@account/typetanium';
import classnames from 'classnames';
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { bem, getIsError, UtilityClass } from '../../utils';
import { FormMessage } from '../FormMessage';
import './MultiLineDropdown.scss';
import { MultiLineDropdownOption, MultiLineDropdownOptionText, MultiLineDropdownOptionTitle } from './MultiLineDropdownOption';
const name = 'form-dropdown';
const [block, element] = bem(name);
export const MultiLineDropdown = forwardRef(({ 'aria-describedby': ariaDescribedBy, errorMessage, id: _id, isError: _isError, label, name, onChange, onClick, onFocus, options = [], placeholder, value, ...buttonProps }, ref) => {
    var _a;
    const [isExpanded, setIsExpanded] = useState(false);
    const [isFocused, setIsFocused] = useState(false);
    const [height, setHeight] = useState('');
    const { value: selectedIndex, increment: incrementSelectedIndex, decrement: decrementSelectedIndex, setValue: setSelectedIndex } = useCounter({
        max: Math.max(options.length - 1, 0)
    });
    const id = useUID({ defaultValue: _id, prefix: block() + '-' });
    const labelId = `${id}_label`;
    const optionsContainerId = `${id}_options-container`;
    const isError = getIsError({ errorMessage, isError: _isError });
    const errorId = (errorMessage === null || errorMessage === void 0 ? void 0 : errorMessage.children) && isError ? `${id}_error` : '';
    const wrapperRef = useRef(null);
    const optionsRef = useRef(null);
    const buttonRef = useRef(null);
    const avoidEscapeRef = useRef(false);
    /**
     * Create imperative handle so users can focus the input button and provide
     * an API that allows the user to toggle expansion if needed. This is more
     * usefal than having a ref that is passed directly to the button.
     */
    useImperativeHandle(ref, () => ({
        focus() {
            var _a;
            /* istanbul ignore next - current is always defined in test context */
            (_a = buttonRef.current) === null || _a === void 0 ? void 0 : _a.focus();
        },
        focusAndExpand(needsAvoidEscape = true) {
            var _a;
            if (needsAvoidEscape) {
                avoidEscapeRef.current = true;
            }
            /* istanbul ignore next - current is always defined in test context */
            (_a = buttonRef.current) === null || _a === void 0 ? void 0 : _a.focus();
            setIsExpanded(true);
        }
    }));
    const escapeFocus = (event) => {
        var _a;
        /* istanbul ignore next - current is always defined in test context */
        if (!((_a = wrapperRef.current) === null || _a === void 0 ? void 0 : _a.contains(event.target))) {
            if (avoidEscapeRef.current) {
                avoidEscapeRef.current = false;
                return;
            }
            setIsFocused(false);
            setIsExpanded(false);
        }
    };
    useGlobalEvent('click', escapeFocus);
    useGlobalEvent('focusIn', escapeFocus);
    // Manually setting the height of the button is required for ac-forms styling.
    useLayoutEffect(() => {
        var _a, _b;
        /* istanbul ignore next - current is always defined in test context */
        const optionsHeight = (_a = optionsRef.current) === null || _a === void 0 ? void 0 : _a.offsetHeight;
        /* istanbul ignore if - optionsHeight is always defined in test context */
        if (isUndefined(optionsHeight)) {
            return;
        }
        /* istanbul ignore next - current is always defined in test context */
        const buttonHeight = (_b = buttonRef.current) === null || _b === void 0 ? void 0 : _b.offsetHeight;
        /* istanbul ignore if - buttonHeight is always defined in test context */
        if (isUndefined(buttonHeight)) {
            return;
        }
        setHeight(isExpanded ? `${optionsHeight + buttonHeight}px` : '');
    }, [buttonRef, optionsRef, isExpanded]);
    const selectedInput = useMemo(() => {
        var _a, _b;
        const selectedOption = (_b = (_a = optionsRef.current) === null || _a === void 0 ? void 0 : _a.children) === null || _b === void 0 ? void 0 : _b[selectedIndex];
        return selectedOption ? selectedOption.querySelector('input') : null;
    }, [(_a = optionsRef.current) === null || _a === void 0 ? void 0 : _a.children, selectedIndex]);
    // On selection & expansion, focus the correct input.
    useEffect(() => {
        if (isExpanded) {
            selectedInput === null || selectedInput === void 0 ? void 0 : selectedInput.focus();
        }
    }, [selectedInput, isExpanded]);
    const onOptionChange = useCallback((event) => {
        var _a;
        setIsExpanded(false);
        /* istanbul ignore next - current is always defined in test context */
        (_a = buttonRef.current) === null || _a === void 0 ? void 0 : _a.focus();
        onChange(event);
    }, [onChange, buttonRef.current]);
    const onListKeyDown = useCallback((event) => {
        var _a;
        if (!isExpanded) {
            return;
        }
        switch (event.key) {
            case 'Enter':
            case 'Escape':
                event.preventDefault();
                if (event.key === 'Enter') {
                    selectedInput === null || selectedInput === void 0 ? void 0 : selectedInput.click();
                }
                setIsExpanded(false);
                /* istanbul ignore next - current is always defined in test context */
                (_a = buttonRef.current) === null || _a === void 0 ? void 0 : _a.focus();
                break;
            case 'ArrowDown':
            case 'ArrowRight':
                event.preventDefault();
                incrementSelectedIndex();
                break;
            case 'ArrowUp':
            case 'ArrowLeft':
                event.preventDefault();
                decrementSelectedIndex();
                break;
        }
    }, [
        isExpanded,
        buttonRef.current,
        optionsRef.current,
        selectedInput,
        setIsExpanded,
        incrementSelectedIndex,
        decrementSelectedIndex
    ]);
    return (React.createElement("div", { className: classnames(block(), { [UtilityClass.IsError]: isError }), ref: wrapperRef },
        React.createElement("div", { className: classnames(element('multiline'), {
                focused: isFocused,
                'is-expanded': isExpanded
            }) },
            React.createElement("button", { "aria-controls": optionsContainerId, "aria-expanded": isExpanded, "aria-haspopup": "listbox", "aria-labelledby": labelId, ...pickByTruthy({
                    'aria-describedby': classnames(errorId, ariaDescribedBy),
                    'aria-invalid': isError
                }), className: classnames(element('select'), !value && !placeholder && element('selectnone')), id: id, onClick: (event) => {
                    setIsFocused(true);
                    setIsExpanded(!isExpanded);
                    onClick === null || onClick === void 0 ? void 0 : onClick(event);
                }, onFocus: (event) => {
                    setIsFocused(true);
                    onFocus === null || onFocus === void 0 ? void 0 : onFocus(event);
                }, ref: buttonRef, style: { height }, type: "button", ...buttonProps },
                React.createElement("span", { className: element('title') }, value || placeholder)),
            React.createElement("span", { "aria-hidden": true, className: element('chevron') }),
            React.createElement("span", { "aria-hidden": true, className: element('label'), id: labelId }, label),
            React.createElement("div", { id: optionsContainerId },
                React.createElement("ul", { "aria-label": label, className: element('options'), onClick: () => {
                        var _a;
                        setIsExpanded(false);
                        /* istanbul ignore next - current is always defined in test context */
                        (_a = buttonRef.current) === null || _a === void 0 ? void 0 : _a.focus();
                    }, onKeyDown: onListKeyDown, ref: optionsRef, role: "listbox", tabIndex: -1 }, options.map(({ onChange, onMouseEnter, title, text, ...inputProps }, index) => {
                    var _a, _b;
                    const stringifiedValue = String((_a = inputProps.value) !== null && _a !== void 0 ? _a : title);
                    const childId = `${id}_option_${kebabCase(stringifiedValue)}`;
                    return (React.createElement(MultiLineDropdownOption, { checked: stringifiedValue === value, id: childId, key: childId, name: name, onChange: (event) => {
                            onOptionChange(event);
                            onChange === null || onChange === void 0 ? void 0 : onChange(event);
                        }, onMouseEnter: (event) => {
                            setSelectedIndex(index);
                            onMouseEnter === null || onMouseEnter === void 0 ? void 0 : onMouseEnter(event);
                        }, selected: index === selectedIndex, ...inputProps, value: (_b = inputProps.value) !== null && _b !== void 0 ? _b : String(title) },
                        title && (React.createElement(MultiLineDropdownOptionTitle, null, title)),
                        text && (React.createElement(MultiLineDropdownOptionText, null, text))));
                })))),
        isError && (errorMessage === null || errorMessage === void 0 ? void 0 : errorMessage.children) && (React.createElement(FormMessage, { id: errorId, ...errorMessage }))));
});
