export class EventEmitter {
    constructor() {
        this.registry = new Map();
    }
    /**
     * Synchronously calls each of the listeners registered for the event named
     * `eventName`, in the order they were registered, passing the supplied
     * arguments to each.
     */
    emit(eventName, ...rest) {
        var _a;
        const listeners = (_a = this.registry.get(eventName)) !== null && _a !== void 0 ? _a : [];
        for (const listener of listeners) {
            listener(...rest);
        }
    }
    /**
     * Adds the `listener` to the `eventName` set.
     * @returns a function that removes the `listener` from the `eventName` set.
     */
    on(eventName, listener) {
        {
            let listeners = this.registry.get(eventName);
            if (!listeners) {
                this.registry.set(eventName, (listeners = new Set()));
            }
            listeners.add(listener);
        }
        /**
         * Removes the `listener` from the `eventName` set.
         */
        const off = () => {
            const listeners = this.registry.get(eventName);
            if (listeners && listeners.has(listener)) {
                if (listeners.size === 1) {
                    return this.registry.delete(eventName);
                }
                else {
                    return listeners.delete(listener);
                }
            }
            return false;
        };
        return off;
    }
}
