import React, { ReactElement, useContext, useState } from 'react'
import {
  Alert,
  AlertContent,
  AlertButtonBar,
  Button,
  AlertDescription,
  AlertHeader,
  AlertHero,
  AlertTitle,
} from '@account/amk-components'
import { AMKClient } from '@account/amk-client'
import I18nContext from '@components/i18n-context'
import ApiContext from '@components/api-context'
import { FamilyDetails } from '@lib/api'
import AccountPortalIcon from '@account/amk-icons/expanded/AccountPortal.svg'

const ConfirmUnlink = ({
  amkClient,
  familyDetails,
}: {
  amkClient: AMKClient
  familyDetails?: FamilyDetails
}): ReactElement | null => {
  const i18n = useContext(I18nContext)
  const api = useContext(ApiContext)
  const [isWorking, setIsWorking] = useState(false)

  if (
    !i18n ||
    !api ||
    !familyDetails?.currentDsid ||
    !familyDetails?.family ||
    !familyDetails?.familyMembers
  ) {
    return null
  }

  const {
    currentDsid,
    currentUserAppleId,
    family: { familyId, organizerDsid },
    familyMembers,
  } = familyDetails

  const organizer = familyMembers.find(
    (member) => organizerDsid !== null && member.dsid === organizerDsid
  )

  const title = i18n.getLoc('com.apple.cloud.familyws.unlink.alert.title')
  const description = i18n.getLoc(
    'com.apple.cloud.familyws.unlink.alert.description',
    // Variable is @@organizerFullName@@ so we need to change the prefix.
    i18n.getNameReplacements(organizer, 'organizer')
  )
  const onDismiss = () => {
    amkClient.hide()
  }

  return (
    <Alert axLabel={title} onDismiss={onDismiss} visible>
      <AlertContent>
        <AlertHeader>
          <AlertHero>
            <AccountPortalIcon />
          </AlertHero>
          <AlertTitle>{title}</AlertTitle>
          <AlertDescription>{description}</AlertDescription>
        </AlertHeader>
      </AlertContent>
      <AlertButtonBar workingText={isWorking && title}>
        <Button onClick={onDismiss}>
          {i18n.getLoc('com.apple.cloud.familyws.common.button.cancel')}
        </Button>
        <Button
          color="secondary"
          onClick={async () => {
            setIsWorking(true)
            const updatedFamilyData = await api.unlinkAccount({
              currentDsid,
              familyId,
              currentUserAppleId,
            })
            amkClient.hide(updatedFamilyData)
            setIsWorking(false)
          }}
        >
          {i18n.getLoc('com.apple.cloud.familyws.unlink.alert.button.confirm')}
        </Button>
      </AlertButtonBar>
    </Alert>
  )
}

export default ConfirmUnlink
