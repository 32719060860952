import { useUID } from '@account/react-hooks';
import { pickByTruthy } from '@account/typetanium';
import classnames from 'classnames';
import React, { forwardRef } from 'react';
import { bem, UtilityClass } from '../../utils';
import { getIsError } from '../../utils/getIsError';
import { FormMessage } from '../FormMessage';
import './Dropdown.scss';
const [block, element] = bem('form-dropdown');
const _Dropdown = ({ 'aria-describedby': ariaDescribedBy, children, errorMessage, id: _id, isError: _isError, labelText, value, ltr = false, ...selectProps }, ref) => {
    const id = useUID({ defaultValue: _id, prefix: block() + '-' });
    const isError = getIsError({ isError: _isError, errorMessage });
    const errorId = isError ? `${id}_error` : '';
    return (React.createElement("div", { className: classnames(block({ ltr }), {
            [UtilityClass.IsError]: isError
        }) },
        React.createElement("select", { ...pickByTruthy({
                'aria-describedby': classnames(errorId, ariaDescribedBy),
                'aria-invalid': isError,
                value
            }), className: classnames(element('select'), !value && element('selectnone')), id: id, ref: ref, ...selectProps }, children),
        React.createElement("span", { "aria-hidden": true, className: element('chevron') }),
        React.createElement("label", { "aria-hidden": true, className: element('label'), htmlFor: id }, labelText),
        errorMessage && React.createElement(FormMessage, { id: errorId, ...errorMessage })));
};
export const Dropdown = forwardRef(_Dropdown);
