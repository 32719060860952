import { button } from '@account/amk-styles';
import { Button as StandardsButton } from '@account/standards-components';
import React from 'react';
const [block] = button;
/**
 * @see https://standards.apple.com/element-library/#pattern-button
 */
export const Button = ({ iconOnly, bare, expand, shape, link, ...buttonProps }) => (React.createElement(StandardsButton
// @ts-expect-error slip in additional modifier class names
, { 
    // @ts-expect-error slip in additional modifier class names
    className: buttonClassNames({
        iconOnly,
        bare,
        expand,
        link,
        shape
    }), ...buttonProps }));
export function buttonClassNames({ iconOnly, bare, expand, link, shape = 'rounded-rectangle' }) {
    return block({
        'icon-only': iconOnly,
        bare,
        expand,
        link,
        [shape]: shape !== 'pill'
    })
        .split(' ')
        .slice(1)
        .join(' ');
}
