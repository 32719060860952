import React from 'react';
import { Card, CardListItem } from '.';
import { Button } from '../Button';
export const ListItemPlatterCard = ({ onClick, ...restProps }) => {
    const cardProps = {
        theme: 'platter',
        heading: false,
        ...restProps
    };
    return (React.createElement(CardListItem, { platter: true }, onClick ? (React.createElement(Button, { bare: true, expand: true, onClick: onClick },
        React.createElement(Card, { ...cardProps }))) : (React.createElement(Card, { ...cardProps }))));
};
