import { EventEmitter } from '@account/event-emitter';
import { uid } from '@account/uid';
import { checkIsValidMessage, decode, encode } from './message';
const MAX_HANDSHAKE_ATTEMPTS = 3;
const DEFAULT_TIMEOUT = 5000;
const handshakeEvents = new Set(['SYN', 'SYN/ACK', 'ACK']);
const reserveEvents = new Set([...handshakeEvents, 'connected']);
export class WindowConnection extends EventEmitter {
    constructor(target, targetOrigin, { source = window, timeout = DEFAULT_TIMEOUT, namespace = '' } = {}) {
        super();
        this.target = target;
        this.targetOrigin = targetOrigin;
        this.preConnectionEvents = [];
        if (targetOrigin === '*')
            throw new Error("targetOrigin can not be '*'");
        this.source = source;
        this.namespace = namespace;
        this.isConnected = false;
        this.isListening = false;
        this.timeout = timeout;
        this.logs = [];
    }
    handleMessageEvent(messageEvent) {
        if (messageEvent.origin === this.targetOrigin &&
            messageEvent.source === this.target) {
            if (checkIsValidMessage(this.namespace, messageEvent.data)) {
                const messageEventData = decode(this.namespace, messageEvent.data);
                const { winio, event } = messageEventData;
                if (handshakeEvents.has(event.name) ||
                    (this.isConnected && winio.cid === this.connectionID)) {
                    this.log({ type: 'on', data: messageEventData });
                    super.emit(event.name, event.payload);
                }
                else {
                    // Since postMessage doesn't have a guarantee of order
                    // we need to queue up all the winio non handshake events
                    // so we can process them once we know for certain what
                    // the connection id is going to be.
                    this.preConnectionEvents.push(messageEvent);
                }
            }
        }
    }
    async startHandshake() {
        let attempts = 0;
        let totalTime = 0;
        let retryTimeout = Math.ceil(this.timeout / 2 ** MAX_HANDSHAKE_ATTEMPTS);
        while (attempts < MAX_HANDSHAKE_ATTEMPTS) {
            try {
                if (attempts === MAX_HANDSHAKE_ATTEMPTS - 1 &&
                    retryTimeout < this.timeout - totalTime) {
                    retryTimeout = this.timeout - totalTime;
                }
                await this.attemptHandshake(retryTimeout);
                return;
            }
            catch {
                totalTime += retryTimeout;
                attempts += 1;
                retryTimeout *= 2;
            }
        }
        throw new Error('Handshake failed');
    }
    attemptHandshake(timeout) {
        this.handshakePromise = new Promise((resolve, reject) => {
            this.resolveHandshake = resolve;
            setTimeout(() => {
                if (!this.isConnected) {
                    reject();
                }
            }, timeout);
        });
        this.internalEmit('SYN', { connectionID: uid() });
        return this.handshakePromise;
    }
    handshakeAcknowledged(connectionID) {
        var _a;
        this.isConnected = true;
        this.connectionID = connectionID;
        (_a = this.resolveHandshake) === null || _a === void 0 ? void 0 : _a.call(this);
        super.emit('connected');
        if (this.preConnectionEvents.length > 0) {
            this.preConnectionEvents.forEach((messageEvent) => {
                this.handleMessageEvent(messageEvent);
            });
            this.preConnectionEvents = [];
        }
    }
    emit(name, payload) {
        if (reserveEvents.has(name)) {
            throw new Error(`"${name}" is a reserve event.`);
        }
        if (!this.isConnected) {
            throw new Error('Connection has to be establish before emitting events.');
        }
        this.internalEmit(name, payload);
    }
    //use only internally, doesn't have the same checks as the public emit method.
    internalEmit(name, payload) {
        const emitObject = {
            winio: {
                cid: this.connectionID,
                eid: uid()
            },
            event: {
                name,
                payload
            }
        };
        this.log({ type: 'emit', data: emitObject });
        this.target.postMessage(encode(this.namespace, emitObject), this.targetOrigin);
    }
    open() {
        if (this.isListening)
            throw new Error("Can't use both 'open' and 'connect' in the same connection");
        //subscribe to handshake events
        this.on('SYN', (payload) => {
            this.internalEmit('SYN/ACK', { connectionID: payload.connectionID });
        });
        this.on('SYN/ACK', (payload) => {
            this.internalEmit('ACK', { connectionID: payload.connectionID });
            this.handshakeAcknowledged(payload.connectionID);
        });
        this.on('ACK', (payload) => {
            this.handshakeAcknowledged(payload.connectionID);
        });
        this.source.addEventListener('message', (event) => this.handleMessageEvent(event), false);
        this.isListening = true;
    }
    connect() {
        if (this.isListening)
            throw new Error("Can't use both 'open' and 'connect' in the same connection");
        this.open();
        return this.startHandshake();
    }
    getLogs() {
        return this.logs;
    }
    log(log) {
        this.logs.push(log);
    }
}
