import React from 'react';
import { bem } from '../../utils';
import { AxLabeled } from '../AxLabeled';
const [block] = bem('form-icons');
export const FormIconButton = ({ axLabel, children, glyph, weight, ...buttonProps }) => {
    var _a;
    if (weight) {
        /* istanbul ignore next */
        (buttonProps.style = (_a = buttonProps.style) !== null && _a !== void 0 ? _a : {}).fontWeight = weight;
    }
    return (React.createElement("button", { className: block({ [glyph]: true }), type: "button", ...buttonProps },
        React.createElement(AxLabeled, { axLabel: axLabel }, children)));
};
