import { useUID } from '@account/react-hooks';
import { pickByTruthy } from '@account/typetanium';
import classnames from 'classnames';
import React, { forwardRef } from 'react';
import { bem, UtilityClass } from '../../utils';
import { Icon, IconGlyph } from '../Icon';
const name = 'form-dropdown-option';
const [block, element] = bem(name);
export const MultiLineDropdownOption = forwardRef(({ id: _id, checked, selected, onClick, onMouseEnter, children, ...inputProps }, ref) => {
    const id = useUID({ defaultValue: _id, prefix: block() + '-' });
    return (React.createElement("li", { className: classnames(block(), { checked, selected }), role: "option", tabIndex: -1, ...pickByTruthy({
            'aria-current': checked,
            'aria-selected': selected,
            onMouseEnter
        }) },
        React.createElement("input", { checked: checked, className: UtilityClass.VisuallyHidden, id: id, ref: ref, type: "radio", ...inputProps }),
        React.createElement("label", { htmlFor: id },
            children,
            checked && React.createElement(Icon, { glyph: IconGlyph.Check }))));
});
export const MultiLineDropdownOptionTitle = (props) => (React.createElement("div", { className: element('title'), ...props }));
export const MultiLineDropdownOptionText = (props) => (React.createElement("div", { className: element('text'), ...props }));
