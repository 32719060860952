import { TextField } from '@account/standards-components';
import { isUndefined, pickByTruthy } from '@account/typetanium';
import React, { useCallback, useMemo } from 'react';
const nonDigits = /\D/g;
export const NumericTextField = ({ maxLength, minLength = 0, onChange, onInput, onKeyDown, onKeyUp, ...textFieldProps }) => {
    const max = useMemo(() => maxLength !== null && maxLength !== void 0 ? maxLength : Infinity, [maxLength]);
    const fixOnChange = createHandler(max, onChange);
    const fixOnInput = createHandler(max, onInput);
    return (React.createElement(TextField, { ...pickByTruthy({
            onChange: onChange && fixOnChange,
            onInput: onInput && fixOnInput
        }), pattern: useMemo(() => `\\d{${minLength}${minLength === maxLength ? '' : ','}${maxLength === minLength || isUndefined(maxLength) ? '' : maxLength}}`, [minLength, maxLength]), type: "tel", ...{ minLength }, ...textFieldProps }));
};
function createHandler(maxLength, originalHandler) {
    return useCallback((event) => {
        const t = event.currentTarget;
        if (nonDigits.test(t.value)) {
            t.value = t.value.replace(nonDigits, '');
        }
        if (t.value.length > maxLength) {
            t.value = t.value.substr(0, maxLength);
        }
        // If the originalHandler were falsey then this callback will never be called.
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        originalHandler(event);
    }, [maxLength, originalHandler]);
}
