import { useUID } from '@account/react-hooks';
import React from 'react';
import { FormIconGlyph } from '../FormIcon';
import { FormIconButton } from '../FormIconButton';
import { Tooltip } from './Tooltip';
import './Tooltip.scss';
import { TooltipContainer } from './TooltipContainer';
export const TooltipButton = (props) => {
    const { axLabel, position, formIconGlyph = FormIconGlyph.Info19, ...tooltipProps } = props;
    const tooltipID = useUID();
    const [focused, setFocused] = React.useState(false);
    const [hovered, setHovered] = React.useState(false);
    const onFocus = () => {
        setFocused(true);
    };
    const onBlur = () => {
        setFocused(false);
    };
    const onMouseEnter = () => {
        setHovered(true);
    };
    const onMouseLeave = () => {
        setHovered(false);
    };
    return (React.createElement(TooltipContainer, { show: focused || hovered, position: position },
        React.createElement(FormIconButton, { "aria-describedby": tooltipID, glyph: formIconGlyph, ...{
                axLabel,
                onFocus,
                onBlur,
                onMouseEnter,
                onMouseLeave
            } }),
        React.createElement(Tooltip, { id: tooltipID, pointerPosition: "right", ...tooltipProps })));
};
