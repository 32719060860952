import { image } from '@account/amk-styles';
import React, { useState } from 'react';
const [block] = image;
export const Image = ({ circle = false, cover = false, width, height, cropRectangle, cropRectangleOrigin = 'topLeft', ...imgProps }) => {
    const [imageSize, setImageSize] = useState();
    const styles = cropRectangle &&
        (imageSize
            ? {
                transform: `scale(${width / cropRectangle.width}, ${height / cropRectangle.height})`,
                width: `${cropRectangle.width}px`,
                height: `${cropRectangle.height}px`,
                objectPosition: `${cropRectangle.left * -1}px ${(cropRectangleOrigin !== 'topLeft'
                    ? imageSize.height - cropRectangle.top - cropRectangle.height
                    : cropRectangle.top) * -1}px`
            }
            : { visibility: 'hidden' });
    return cropRectangle ? (React.createElement("div", { style: { width: `${width}px`, height: `${height}px`, overflow: 'hidden' } },
        React.createElement("img", { className: block({ cropped: !!cropRectangle, circle }), style: styles, onLoad: ({ target }) => {
                setImageSize({
                    width: target.width,
                    height: target.height
                });
            }, ...imgProps }))) : (React.createElement("img", { className: block({ circle, cover }), width: `${width}px`, height: `${height}px`, ...imgProps }));
};
