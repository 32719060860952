import { FamilyMember, I18nApiResponse } from './api'
import {getAgeLabel} from "@lib/util";

export interface InterpolationReplacements {
  [key: string]: string | null | number | undefined
}

export interface LocStrings {
  [locKey: string]: string
}

export default class I18n {
  // Each instance will get the locStrings on instantiation.
  locStrings: LocStrings
  language: string // e.g. en-US
  isRtl: boolean

  constructor({ locStrings, language, isRtl }: I18nApiResponse) {
    this.locStrings = locStrings
    this.language = language
    this.isRtl = isRtl
  }

  getLoc(locKey: string, replacements = {}): string {
    const { interpolateString, locStrings } = this
    return interpolateString(locStrings[locKey], replacements) || locKey
  }

  // Should only be used when supplied from the server.
  interpolateString(
    templateString: string | undefined,
    replacements: InterpolationReplacements
  ): string {
    let interpolatedString = templateString || ''
    if (templateString) {
      for (const i in replacements) {
        const replaceValue = replacements[i]
        // Regex replaces all occurrences, string only replaces one, so we need a regex.
        const regex = new RegExp(`@@${i}@@`, 'g')
        interpolatedString = interpolatedString.replace(
          regex,
          `${replaceValue}`
        )
      }
    }
    return interpolatedString
  }

  getRoleSubtitle({
    member,
    organizerDsid,
    addAgePrefix = true,
  }: {
    member: FamilyMember
    organizerDsid?: string
    addAgePrefix?: boolean
  }): string {
    const { dateOfBirthEpoch, ageClassification, dsid, isParent } = member
    if (organizerDsid === dsid) {
      return this.getLoc(
        'com.apple.cloud.familyws.members.description.organizer'
      )
    } else if (ageClassification === 'ADULT') {
      if (isParent) {
        return this.getLoc(
          'com.apple.cloud.familyws.members.description.parent'
        )
      } else {
        return this.getLoc('com.apple.cloud.familyws.members.description.adult')
      }
    } else {
      if (!dateOfBirthEpoch) {
        return ''
      }
      const ageLabel = getAgeLabel(this, new Date(dateOfBirthEpoch));
      if (addAgePrefix) {
        return this.getLoc('com.apple.cloud.familyws.members.description.minor', {
          age: getAgeLabel(this, new Date(dateOfBirthEpoch)),
        })
      }
      return ageLabel;
    }
  }

  /**
   * Generates the necessary replacements for all languages for a member's name.
   * @param member The member to extract a name.
   * @param prefix Defaults to "member", so params will be "memberFirstName", "memberLastName", "memberFullName"
   */
  getNameReplacements(
    member?: FamilyMember,
    prefix = 'member'
  ): { [nameFieldKey: string]: string } {
    if (!member) {
      return {}
    }
    const { firstName, lastName, fullName } = member
    if (prefix) {
      return {
        [`${prefix}FirstName`]: firstName,
        [`${prefix}LastName`]: lastName,
        [`${prefix}FullName`]: fullName,
      }
    } else {
      return { firstName, lastName, fullName }
    }
  }
}
