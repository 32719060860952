import { brokenLowered } from '../util/brokenLowered';
import { allCapitalLetterGroups } from '../util/regex/allCapitalLetterGroups';
import { allCaps } from '../util/regex/allCaps';
import { allLowercaseWords } from '../util/regex/allLowercaseWords';
import { allWords } from '../util/regex/allWords';
/**
 * Converts a string value into [kebab case](https://en.wikipedia.org/wiki/Kebab_case).
 * @category String
 * @returns The kebab-cased string.
 * @example
 * kebabCase('fooBARBaz'))
 * // => 'foo-bar-baz'
 */
export function kebabCase(value) {
    var _a, _b;
    return ((_b = (_a = value
        .match(allWords)) === null || _a === void 0 ? void 0 : _a.map((word) => allCaps.test(word)
        ? word.toLowerCase()
        : // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            word
                .replace(allCapitalLetterGroups, brokenLowered)
                .match(allLowercaseWords)
                .join('-')).join('-')) !== null && _b !== void 0 ? _b : '');
}
