import React from 'react';
import { bem } from '../../utils';
import { AxLabeled } from '../AxLabeled';
const [block] = bem('form-icons');
export const FormIcon = ({ axLabel, children, glyph, weight, ...spanProps }) => {
    var _a;
    if (weight) {
        /* istanbul ignore next */
        (spanProps.style = (_a = spanProps.style) !== null && _a !== void 0 ? _a : {}).fontWeight = weight;
    }
    return (React.createElement("span", { className: block({ [glyph]: true }), ...spanProps },
        React.createElement(AxLabeled, { axLabel: axLabel }, children)));
};
