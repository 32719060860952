// returns padded hex string
const uint8ToHexDigit = (num) => (num + 0x100).toString(16).substr(1);
const getRandomValues = (length) => crypto.getRandomValues(new Uint8Array(length)); //8bit gives us numbers between 0-255
const hyphenPositions = [4, 6, 8, 10];
/**
 * UUID string with format of the form:
 * XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX
 * https://www.cryptosys.net/pki/uuid-rfc4122.html
 * https://tools.ietf.org/html/rfc4122
 */
export const uuid = () => {
    //Generate 16 random bytes (=128 bits)
    const randomValues = getRandomValues(16);
    // Adjust certain bits according to RFC 4122 section 4.4 as follows
    // set the four most significant bits of the 7th byte to 0100'B, so the high nibble is "4"
    randomValues[6] = (randomValues[6] & 0x0f) | 0x40;
    // set the two most significant bits of the 9th byte to 10'B, so the high nibble will be one of "8", "9", "A", or "B".
    randomValues[8] = (randomValues[8] & 0x3f) | 0x80;
    // Encode to UUID string.
    let uuid = '';
    for (let i = 0; i < randomValues.length; i++) {
        if (hyphenPositions.includes(i)) {
            uuid += '-';
        }
        uuid += uint8ToHexDigit(randomValues[i]);
    }
    return uuid;
};
