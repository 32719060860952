/**
 * Creates an object composed of the object properties for which predicate
 * returns truthy.
 * @category Object
 * @param object The source object.
 * @param predicate The function invoked per property.
 * @returns The new object.
 * @example
 * const obj = { a: 0, b: '', c: true, d: 'hello' }
 * pickBy(obj, Boolean)
 * // => { c: true, d: 'hello' }
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function pickBy(object, predicate) {
    return Object.keys(object).reduce((result, key) => {
        const value = object[key];
        if (predicate(value, key)) {
            result[key] = value;
        }
        return result;
    }, {});
}
