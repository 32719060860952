import { securityCode } from '@account/amk-styles';
import { useUID } from '@account/react-hooks';
import { FormMessage } from '@account/standards-components';
import classnames from 'classnames';
import React, { createRef, Fragment, useEffect, useState } from 'react';
import { getKeyboardEventKeyCode, KeyCode } from '../../utils';
const [block, element] = securityCode;
export const SecurityCode = ({ value, axLabel, axDigitLabel, onChange, errorMessage, isError: _isError, split = true, inputType = 'tel' }) => {
    const shouldSplit = split && value.length % 2 === 0;
    const errorId = useUID({ prefix: block() + '-error-' });
    const isError = _isError !== null && _isError !== void 0 ? _isError : !!(errorMessage === null || errorMessage === void 0 ? void 0 : errorMessage.children);
    const [inputRef, setInputRefs] = useState([]);
    const [inputSelection, setInputSelection] = useState([]);
    useEffect(() => {
        setInputRefs(value.map((_, index) => inputRef[index] || createRef()));
        setInputSelection(value.map((_, index) => inputSelection[index] || { start: 0, end: 0 }));
    }, [value.length]);
    const createNewValue = (index, patchValue) => {
        const newValue = [...value];
        if (patchValue.length + index > value.length) {
            const deltaLength = patchValue.length + index - value.length;
            patchValue = patchValue.slice(0, patchValue.length - deltaLength);
        }
        newValue.splice(index, patchValue.length, ...patchValue);
        return newValue;
    };
    const focusNext = (currentIndex) => {
        const nextElement = inputRef[currentIndex + 1 < value.length ? currentIndex + 1 : value.length - 1].current;
        nextElement.focus();
    };
    const focusPrevious = (currentIndex) => {
        const previousElement = inputRef[currentIndex - 1 > 0 ? currentIndex - 1 : 0].current;
        previousElement.focus();
    };
    const onInputChange = (i) => (event) => {
        const { value: newInputValue } = event.target;
        const newValue = newInputValue.replace(/\D/g, '').split('');
        const currentInputValue = value[i];
        if (currentInputValue !== '' &&
            inputSelection[i].start === inputSelection[i].end) {
            newValue.splice(inputSelection[i].start === 0 ? -1 : 0, 1);
        }
        if (newValue.length) {
            onChange(createNewValue(i, newValue));
            focusNext(i + newValue.length - 1);
        }
    };
    const onInputKeyDown = (i) => (event) => {
        switch (getKeyboardEventKeyCode(event)) {
            case KeyCode.BACKSPACE:
                if (i === 0 || value[i]) {
                    onChange(createNewValue(i, ['']));
                }
                else {
                    onChange(createNewValue(i - 1, ['']));
                    focusPrevious(i);
                }
                break;
            case KeyCode.DELETE:
                if (i === value.length - 1 || value[i]) {
                    onChange(createNewValue(i, ['']));
                }
                else {
                    onChange(createNewValue(i + 1, ['']));
                    focusNext(i);
                }
                break;
            case KeyCode.LEFT_ARROW:
                focusPrevious(i);
                break;
            case KeyCode.RIGHT_ARROW:
                focusNext(i);
                break;
            default:
                break;
        }
    };
    const onInputSelect = (i) => (event) => {
        inputSelection[i].start =
            event.target.selectionStart || 0;
        inputSelection[i].end =
            event.target.selectionEnd || 0;
    };
    return (React.createElement("div", { className: classnames(block(), { 'is-error': isError }) },
        React.createElement("div", { className: element('inputs') }, inputRef.map((ref, index) => {
            return (React.createElement(Fragment, { key: index },
                shouldSplit && Math.floor(inputRef.length / 2) === index && (React.createElement("div", { className: element('divider') })),
                React.createElement("input", { ref: ref, className: element('input', { 'no-caret': !!value[index] }), type: inputType, autoCapitalize: "off", autoCorrect: "off", spellCheck: false, autoComplete: "off", value: value[index], onChange: onInputChange(index), onKeyDown: onInputKeyDown(index), onSelect: onInputSelect(index), "aria-invalid": isError || undefined, "aria-describedby": isError && (errorMessage === null || errorMessage === void 0 ? void 0 : errorMessage.children) ? errorId : undefined, "aria-label": index === 0
                        ? `${axLabel} ${axDigitLabel} ${index + 1}`
                        : `${axDigitLabel} ${index + 1}` })));
        })),
        isError && (errorMessage === null || errorMessage === void 0 ? void 0 : errorMessage.children) && (React.createElement(FormMessage, { id: errorId, ...errorMessage }))));
};
