import { AnchorButton as StandardsAnchorButton } from '@account/standards-components';
import React from 'react';
import { buttonClassNames } from './Button';
/**
 * @see https://standards.apple.com/element-library/#pattern-button
 */
export const AnchorButton = ({ iconOnly, shape, ...anchorProps }) => (React.createElement(StandardsAnchorButton
// @ts-expect-error slip in additional modifier class names
, { 
    // @ts-expect-error slip in additional modifier class names
    className: buttonClassNames({
        iconOnly,
        shape
    }), ...anchorProps }));
