import React from 'react';
import { Image } from './Image';
import { ProfileImagePlaceholder } from './ProfileImagePlaceholder';
export const ProfileImage = ({ profileImage, size, alt, ...imageProps }) => {
    var _a;
    //if either width or height is falsy (0) then we can't use the cropRectangle.
    const cropRectangle = ((_a = profileImage === null || profileImage === void 0 ? void 0 : profileImage.cropRectangle) === null || _a === void 0 ? void 0 : _a.width) && profileImage.cropRectangle.height
        ? profileImage.cropRectangle
        : undefined;
    return profileImage ? (React.createElement(Image, { src: `data:image/png;base64,${profileImage.imageData}`, height: size, width: size, alt: alt, cropRectangle: cropRectangle, cropRectangleOrigin: "bottomLeft", cover: !cropRectangle, ...imageProps, circle: true })) : (React.createElement(ProfileImagePlaceholder, { size: size, alt: alt }));
};
