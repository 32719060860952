import { useUID } from '@account/react-hooks';
import React, { forwardRef, useState } from 'react';
import { bem } from '../../utils';
import { FormLabel } from '../FormLabel';
const _LabeledInput = ({ baseClassName, children, hasIndicator, id: _id, isLabelAfterInput, ...inputProps }, ref) => {
    const [[block, element]] = useState(bem(baseClassName));
    const id = useUID({ defaultValue: _id, prefix: block() + '-' });
    const label = (React.createElement(FormLabel, { htmlFor: id },
        hasIndicator && React.createElement("span", { className: element('indicator') }),
        children));
    return (React.createElement("div", { className: block() },
        !isLabelAfterInput && label,
        React.createElement("input", { className: element('input'), id: id, ref: ref, ...inputProps }),
        isLabelAfterInput && label));
};
export const LabeledInput = forwardRef(_LabeledInput);
