/**
 * @see https://standards.apple.com/element-library/#pattern-icons
 */
export var IconGlyph;
(function (IconGlyph) {
    IconGlyph["Apple"] = "apple";
    IconGlyph["ChevronDown"] = "chevrondown";
    IconGlyph["ChevronDownCircle"] = "chevrondowncircle";
    IconGlyph["ChevronLeft"] = "chevronleft";
    IconGlyph["ChevronLeftCircle"] = "chevronleftcircle";
    IconGlyph["ChevronRight"] = "chevronright";
    IconGlyph["ChevronRightCircle"] = "chevronrightcircle";
    IconGlyph["ChevronUp"] = "chevronup";
    IconGlyph["ChevronUpCircle"] = "chevronupcircle";
    IconGlyph["DownloadCircle"] = "downloadcircle";
    IconGlyph["ArrowUpCircle"] = "arrowupcircle";
    IconGlyph["External"] = "external";
    IconGlyph["ExternalRtl"] = "externalrtl";
    IconGlyph["Share"] = "share";
    IconGlyph["Search"] = "search";
    IconGlyph["ArKit"] = "arkit";
    IconGlyph["PauseCircle"] = "pausecircle";
    IconGlyph["PauseSolid"] = "pausesolid";
    IconGlyph["PlayCircle"] = "playcircle";
    IconGlyph["PlaySolid"] = "playsolid";
    IconGlyph["Replay"] = "replay";
    IconGlyph["StopCircle"] = "stopcircle";
    IconGlyph["StopSolid"] = "stopsolid";
    IconGlyph["Circle"] = "circle";
    IconGlyph["Check"] = "check";
    IconGlyph["CheckCircle"] = "checkcircle";
    IconGlyph["CheckSolid"] = "checksolid";
    IconGlyph["Reset"] = "reset";
    IconGlyph["ResetCircle"] = "resetcircle";
    IconGlyph["ResetSolid"] = "resetsolid";
    IconGlyph["Exclamation"] = "exclamation";
    IconGlyph["ExclamationCircle"] = "exclamationcircle";
    IconGlyph["ExclamationSolid"] = "exclamationsolid";
    IconGlyph["ExclamationTriangle"] = "exclamationtriangle";
    IconGlyph["ExclamationTriangleSolid"] = "exclamationtrianglesolid";
    IconGlyph["InfoCircle"] = "infocircle";
    IconGlyph["InfoSolid"] = "infosolid";
    IconGlyph["Question"] = "question";
    IconGlyph["QuestionRtl"] = "questionrtl";
    IconGlyph["QuestionCircle"] = "questioncircle";
    IconGlyph["QuestionCircleRtl"] = "questioncirclertl";
    IconGlyph["QuestionSolid"] = "questionsolid";
    IconGlyph["QuestionSolidRtl"] = "questionsolidrtl";
    IconGlyph["Plus"] = "plus";
    IconGlyph["PlusCircle"] = "pluscircle";
    IconGlyph["PlusSolid"] = "plussolid";
    IconGlyph["Minus"] = "minus";
    IconGlyph["MinusCircle"] = "minuscircle";
    IconGlyph["MinusSolid"] = "minussolid";
    IconGlyph["OneCircle"] = "1circle";
    IconGlyph["TwoCircle"] = "2circle";
    IconGlyph["ThreeCircle"] = "3circle";
    IconGlyph["FourCircle"] = "4circle";
    IconGlyph["FiveCircle"] = "5circle";
    IconGlyph["SixCircle"] = "6circle";
    IconGlyph["SevenCircle"] = "7circle";
    IconGlyph["EightCircle"] = "8circle";
    IconGlyph["NineCircle"] = "9circle";
    IconGlyph["TenCircle"] = "10circle";
    IconGlyph["ElevenCircle"] = "11circle";
    IconGlyph["TwelveCircle"] = "12circle";
    IconGlyph["ThirteenCircle"] = "13circle";
    IconGlyph["FourteenCircle"] = "14circle";
    IconGlyph["FifteenCircle"] = "15circle";
    IconGlyph["SixteenCircle"] = "16circle";
    IconGlyph["SeventeenCircle"] = "17circle";
    IconGlyph["EighteenCircle"] = "18circle";
    IconGlyph["NineteenCircle"] = "19circle";
    IconGlyph["TwentyCircle"] = "20circle";
    IconGlyph["PaddleLeft"] = "paddleleft";
    IconGlyph["PaddleLeftCompact"] = "paddleleftcompact";
    IconGlyph["PaddleRight"] = "paddleright";
    IconGlyph["PaddleRightCompact"] = "paddlerightcompact";
    IconGlyph["PaddleUp"] = "paddleup";
    IconGlyph["PaddleUpCompact"] = "paddleupCompact";
    IconGlyph["PaddleDown"] = "paddledown";
    IconGlyph["PaddleDownCompact"] = "paddledownCompact";
    IconGlyph["Close"] = "close";
    IconGlyph["CloseCompact"] = "closecompact";
    IconGlyph["ThumbnailReplay"] = "thumbnailreplay";
    IconGlyph["ThumbnailPause"] = "thumbnailpaurse";
    IconGlyph["ThumbnailPlay"] = "thumbnailplay";
})(IconGlyph || (IconGlyph = {}));
