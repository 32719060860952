import { errorMessage } from '@account/amk-styles';
import { Icon, IconGlyph } from '@account/standards-components';
import React from 'react';
const [block, element] = errorMessage;
export const ErrorMessage = ({ children, inline = false, solid = true, ...divProps }) => (React.createElement("div", { className: block({ inline }), ...divProps },
    React.createElement("span", { className: element('icon') },
        React.createElement(Icon, { glyph: solid
                ? IconGlyph.ExclamationTriangleSolid
                : IconGlyph.ExclamationTriangle })),
    React.createElement("div", { className: element('text') }, children)));
